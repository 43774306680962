import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Row } from "@cjdev-internal/visual-stack-x/Row";
import { Stack } from "@cjdev-internal/visual-stack-x/Stack";
import { DropDownSelector, DropDownSelectorOption } from "components/DropDownSelector";
import { HorizontalLine } from "components/HorizontalLine";
import { LabeledDateHourSelector } from "components/LabeledFields/LabeledDateHourSelector";
import { LabeledDescription } from "components/LabeledFields/LabeledDescription";
import { FormattedMessage } from "components/FormattedMessage";
import { useIntl, useUser } from "hooks";
import { ScheduleEventFormEntry } from "localTypes";
import { useActiveProgramTermsOptions, useScheduleEventsForm } from "../hooks";
import { currentCompanyId } from "utils/authorizationUtils";

export type ScheduleEventFormProps = {
  index: number;
  scheduleEvent: ScheduleEventFormEntry;
  minDateTime: Date;
  startTimeError?: boolean;
  timeZoneError?: boolean;
};

export const ScheduleEventForm = ({
  index,
  scheduleEvent,
  minDateTime,
  startTimeError,
  timeZoneError,
}: ScheduleEventFormProps) => {
  const { formatMessage } = useIntl();
  const { updateScheduleEvents } = useScheduleEventsForm();
  const { user } = useUser();
  const { activeProgramTermsOptions } = useActiveProgramTermsOptions(currentCompanyId(user), index);

  return (
    <>
      <Stack gap="medium-large">
        <Row gap="medium-large">
          <Box expand>
            <DropDownSelector
              inputId={`${index}-program-terms`}
              label={<FormattedMessage id="relationships.programTerms" defaultMessage="Program Terms" />}
              placeholder={formatMessage({
                id: "relationships.selectProgramTerms",
                defaultMessage: "Select Program Terms",
              })}
              options={activeProgramTermsOptions}
              programTermsError={scheduleEvent.errors.programTerms}
              requiredError={scheduleEvent.errors.programTermsUnselected}
              value={scheduleEvent.programTerms as DropDownSelectorOption}
              onUpdate={(e: DropDownSelectorOption) => updateScheduleEvents(index, "programTerms", e)}
              touchDropDownSelector={() =>
                updateScheduleEvents(
                  index,
                  "programTermsUnselected",
                  scheduleEvent.programTerms as DropDownSelectorOption
                )
              }
            />
          </Box>

          <Box expand>
            <LabeledDateHourSelector
              id={`${index}-start-date-and-time`}
              index={index}
              label={<FormattedMessage id="relationships.startDateAndTime" defaultMessage="Start Date & Time" />}
              startTime={scheduleEvent.startTime}
              updateHandler={updateScheduleEvents}
              required
              minDateTime={minDateTime}
              startTimeError={startTimeError}
              timeZoneError={timeZoneError}
              help={
                index === 0 ? (
                  <FormattedMessage
                    id="relationships.startDateTimeHelp"
                    defaultMessage="Recommended start: 7 days from current date"
                  />
                ) : undefined
              }
            />
          </Box>
        </Row>

        <LabeledDescription
          id={`${index}-description`}
          index={index}
          label={<FormattedMessage id="relationships.description" defaultMessage="Description" />}
          help={
            <FormattedMessage
              id="relationships.descriptionHelp"
              defaultMessage="Description is visible to publishers"
            />
          }
          value={scheduleEvent.description}
          error={scheduleEvent.errors?.description}
        />
      </Stack>
      <HorizontalLine type="dashed" />
    </>
  );
};

export const ScheduleEventMultipleForm = () => {
  const { scheduleEvents } = useScheduleEventsForm();

  return (
    <>
      {scheduleEvents.map((scheduleEvent, index) => (
        <ScheduleEventForm
          key={index}
          index={index}
          scheduleEvent={scheduleEvent}
          minDateTime={scheduleEvent.minDateTime}
          startTimeError={scheduleEvent.errors.startTime}
          timeZoneError={scheduleEvent.errors.timeZone}
        />
      ))}
    </>
  );
};
