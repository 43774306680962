import { createContext, Dispatch, SetStateAction, useEffect } from "react";
import { useState } from "react";
import zonedTimeToUtc from "date-fns-tz/zonedTimeToUtc";
import * as R from "ramda";

import { ProviderProps } from "localTypes/ProviderTypes";
import { Maybe, PostScheduleOfferPayload, ProgramTermsSchedule, ScheduleOfferResponse } from "types";
import { createProgramTermsSchedule } from "api/createProgramTermsSchedule";
import { postScheduleOffer } from "api/postScheduleOffer";
import {
  createScheduleEventModels,
  formatOfferToEntityForApi,
  omitPublishersOrProgramTerms,
} from "utils/offerProgramTermsUtils";
import { navigateToReturnUrl } from "utils/urlUtils";
import { useRelationshipStatuses, useModal, useUser } from "hooks";
import {
  useOfferOptions,
  useScheduleName,
  useOfferSelectedTargets,
  useAlert,
  useScheduleEventsForm,
  useTimeZone,
} from "views/OfferProgramTerms/hooks";
import { useFeatures } from "hooks/useFeatures";
import { currentCompanyId } from "utils/authorizationUtils";

export type OfferProgramTermsContextType = {
  saveOnClickHandler: () => void;
  navigateBackToMember: () => void;
  submitAll: () => void;
  scheduleOfferApiResults: Maybe<ScheduleOfferResponse>;
  setScheduleOfferApiResults: Dispatch<SetStateAction<Maybe<ScheduleOfferResponse>>>;
  serverErrors: Maybe<Error[]>;
  setServerErrors: Dispatch<SetStateAction<Maybe<Error[]>>>;
  providerInitialized: boolean;
};

export const OfferProgramTermsContext = createContext({
  saveOnClickHandler: () => {},
  navigateBackToMember: () => {},
  submitAll: () => {},
  scheduleOfferApiResults: null,
  setScheduleOfferApiResults: () => {},
  serverErrors: [],
  setServerErrors: () => {},
  providerInitialized: false,
} as OfferProgramTermsContextType);

export const OfferProgramTermsProvider = ({ children }: ProviderProps) => {
  const { user } = useUser();
  const { hasFeature } = useFeatures(currentCompanyId(user));

  return hasFeature("PT_SCHEDULING_REVERT_SUPPORT") ? (
    <OfferProgramTermsWithRevertProvider>{children}</OfferProgramTermsWithRevertProvider>
  ) : (
    <LegacyOfferProgramTermsProvider>{children}</LegacyOfferProgramTermsProvider>
  );
};

export const REVERT_TO_CURRENT_TERMS = -1;

const OfferProgramTermsWithRevertProvider = ({ children }: ProviderProps) => {
  const [scheduleOfferApiResults, setScheduleOfferApiResults] = useState<Maybe<ScheduleOfferResponse>>(null);
  const [serverErrors, setServerErrors] = useState<Maybe<Error[]>>(null);

  const { timeZone } = useTimeZone();
  const { selectedPublishers, selectedProgramTerms } = useOfferSelectedTargets();
  const { allowEarlyAccept } = useOfferOptions();
  const { scheduleName, touchScheduleName, isScheduleNameValid } = useScheduleName();

  const {
    scheduleEvents,
    touchAllProgramTerms,
    programTermsUnselectedError,
    programTermsTsAndCsError,
    descriptionError,
  } = useScheduleEventsForm();

  const { openConfirmationModal, openProcessingModal, openConfirmationResultsModal } = useModal();

  const { user } = useUser();
  const advertiserId = currentCompanyId(user);

  const { relationshipStatuses } = useRelationshipStatuses(advertiserId, {
    skip: selectedProgramTerms.length > 0,
  });

  const createSchedulesAndOfferPayloads = async () => {
    const schedule: ProgramTermsSchedule = await createProgramTermsSchedule({
      advertiserId: advertiserId,
      name: scheduleName,
      scheduleEvents: createScheduleEventModels(scheduleEvents, allowEarlyAccept, timeZone.value as string),
    });

    const firstEvent = scheduleEvents[0];
    const selectedDate = new Date(Date.parse(firstEvent.startTime));
    const utcDate = zonedTimeToUtc(selectedDate, timeZone.value as string);

    return omitPublishersOrProgramTerms([
      {
        offerToPublishers: selectedPublishers,
        replaceProgramTerms: selectedProgramTerms,
        supercedeDate: utcDate.toISOString(),
        canAcceptEarly: allowEarlyAccept,
        scheduleId: Number(schedule.id),
      },
    ]) as PostScheduleOfferPayload;
  };

  const createSchedulesAndOfferPayloadsForReversions = async () => {
    const programTermsToPublishers =
      relationshipStatuses &&
      relationshipStatuses.reduce((acc, current) => {
        if (!acc[current.programTermsId]) {
          acc[current.programTermsId] = [];
        }

        acc[current.programTermsId].push(current.publisherId);
        return acc;
      }, {} as Record<string, number[]>);

    const scheduleIdsToPublishers =
      programTermsToPublishers &&
      (await R.keys(programTermsToPublishers).reduce(async (acc, programTermsId) => {
        const scheduleId = await createProgramTermsSchedule({
          advertiserId: advertiserId,
          name: truncateScheduleName(scheduleName, programTermsId),
          scheduleEvents: createScheduleEventModels(
            scheduleEvents,
            allowEarlyAccept,
            timeZone.value as string,
            Number(programTermsId)
          ),
        });

        (await acc)[scheduleId.id] = programTermsToPublishers[programTermsId];
        return acc;
      }, Promise.resolve({} as Record<string, number[]>)));

    const scheduleIds = R.keys(scheduleIdsToPublishers);

    return scheduleIds.map((scheduleId) => {
      const firstEvent = scheduleEvents[0];
      const selectedDate = new Date(Date.parse(firstEvent.startTime));
      const utcDate = zonedTimeToUtc(selectedDate, timeZone.value as string);

      return {
        offerToPublishers: scheduleIdsToPublishers![scheduleId],
        supercedeDate: utcDate.toISOString(),
        canAcceptEarly: allowEarlyAccept,
        scheduleId: parseInt(scheduleId),
      };
    }) as PostScheduleOfferPayload;
  };

  const submitAll = async () => {
    setServerErrors(null);
    setScheduleOfferApiResults(null);
    openProcessingModal();

    const hasReversions = scheduleEvents.some((schedule) => schedule?.programTerms?.value === REVERT_TO_CURRENT_TERMS);

    try {
      const offerPayloads = hasReversions
        ? await createSchedulesAndOfferPayloadsForReversions()
        : await createSchedulesAndOfferPayloads();

      const offerResponse = await postScheduleOffer(advertiserId, offerPayloads);
      setScheduleOfferApiResults(offerResponse);
    } catch (errors) {
      console.error({ errors });
      setServerErrors(Array.isArray(errors) ? (errors as Error[]) : [errors as Error]);
    } finally {
      openConfirmationResultsModal();
    }
  };

  const { startTimesErrors, setShowFormErrorsAlert, evaluateScheduleEventsAlert, evaluateTimeZoneAlert } = useAlert();

  useEffect(() => evaluateScheduleEventsAlert(scheduleEvents), [evaluateScheduleEventsAlert, scheduleEvents]);
  useEffect(() => evaluateTimeZoneAlert(timeZone), [evaluateTimeZoneAlert, timeZone]);

  const saveOnClickHandler = (): void => {
    touchScheduleName();
    touchAllProgramTerms();
    if (
      startTimesErrors ||
      !isScheduleNameValid ||
      programTermsUnselectedError ||
      programTermsTsAndCsError ||
      descriptionError
    ) {
      setShowFormErrorsAlert(true);
    } else {
      setShowFormErrorsAlert(false);
      openConfirmationModal(submitAll);
    }
  };

  return (
    <OfferProgramTermsContext.Provider
      value={
        {
          saveOnClickHandler,
          navigateBackToMember: navigateToReturnUrl,
          submitAll,
          scheduleOfferApiResults,
          setScheduleOfferApiResults,
          serverErrors,
          setServerErrors,
          providerInitialized: true,
        } as OfferProgramTermsContextType
      }
    >
      {children}
    </OfferProgramTermsContext.Provider>
  );
};

const LegacyOfferProgramTermsProvider = ({ children }: ProviderProps) => {
  const [scheduleOfferApiResults, setScheduleOfferApiResults] = useState<Maybe<ScheduleOfferResponse>>(null);
  const [serverErrors, setServerErrors] = useState<Maybe<Error[]>>(null);

  const { selectedPublishers, selectedProgramTerms } = useOfferSelectedTargets();
  const { allowEarlyAccept } = useOfferOptions();

  const { scheduleName, touchScheduleName, isScheduleNameValid } = useScheduleName();

  const {
    scheduleEvents,
    touchAllProgramTerms,
    programTermsUnselectedError,
    programTermsTsAndCsError,
    descriptionError,
  } = useScheduleEventsForm();

  const { openConfirmationModal, openProcessingModal, openConfirmationResultsModal } = useModal();

  const { user } = useUser();
  const advertiserId = currentCompanyId(user);

  const { timeZone } = useTimeZone();

  const submitAll = async () => {
    setServerErrors(null);
    setScheduleOfferApiResults(null);
    openProcessingModal();
    try {
      const schedule: ProgramTermsSchedule = await createProgramTermsSchedule({
        advertiserId: advertiserId,
        name: scheduleName,
        scheduleEvents: createScheduleEventModels(scheduleEvents, allowEarlyAccept, timeZone.value as string),
      });

      const firstEvent = scheduleEvents[0];
      const selectedDate = new Date(Date.parse(firstEvent.startTime));
      const utcDate = zonedTimeToUtc(selectedDate, timeZone.value as string);

      const formattedOfferToEntity = formatOfferToEntityForApi({
        offerToPublishers: selectedPublishers,
        replaceProgramTerms: selectedProgramTerms,
      }) as { offerToPublishers: Array<number> } | { replaceProgramTerms: Array<number> };

      const payload = [
        {
          ...formattedOfferToEntity,
          supercedeDate: utcDate.toISOString(),
          canAcceptEarly: allowEarlyAccept,
          scheduleId: schedule.id,
        },
      ] as PostScheduleOfferPayload;

      const result = await postScheduleOffer(advertiserId, payload);

      setScheduleOfferApiResults(result);
    } catch (errors) {
      console.error({ errors });
      setServerErrors(Array.isArray(errors) ? (errors as Error[]) : [errors as Error]);
    } finally {
      openConfirmationResultsModal();
    }
  };

  const { startTimesErrors, setShowFormErrorsAlert, evaluateScheduleEventsAlert, evaluateTimeZoneAlert } = useAlert();

  useEffect(() => evaluateScheduleEventsAlert(scheduleEvents), [evaluateScheduleEventsAlert, scheduleEvents]);
  useEffect(() => evaluateTimeZoneAlert(timeZone), [evaluateTimeZoneAlert, timeZone]);

  const saveOnClickHandler = (): void => {
    touchScheduleName();
    touchAllProgramTerms();
    if (
      startTimesErrors ||
      !isScheduleNameValid ||
      programTermsUnselectedError ||
      programTermsTsAndCsError ||
      descriptionError
    ) {
      setShowFormErrorsAlert(true);
    } else {
      openConfirmationModal(submitAll);
    }
  };

  return (
    <OfferProgramTermsContext.Provider
      value={
        {
          saveOnClickHandler,
          navigateBackToMember: navigateToReturnUrl,
          submitAll,
          scheduleOfferApiResults,
          setScheduleOfferApiResults,
          serverErrors,
          setServerErrors,
          providerInitialized: true,
        } as OfferProgramTermsContextType
      }
    >
      {children}
    </OfferProgramTermsContext.Provider>
  );
};

const truncateScheduleName = (string: string, programTermsId: string) => {
  const reversionText = ` - revert to ${programTermsId}`;
  const truncated = string.substring(0, 100 - reversionText.length);
  return truncated + reversionText;
};
