import { Heading } from "@cjdev-internal/visual-stack-x/components/Heading";
import { Row } from "@cjdev-internal/visual-stack-x/components/Row";
import { Stack } from "@cjdev-internal/visual-stack-x/components/Stack";
import { Text } from "@cjdev-internal/visual-stack-x/components/Text";
import { Button } from "@cjdev-internal/visual-stack-x/components/Button";
import { FormattedMessage } from "components/FormattedMessage";
import { SimpleModal } from "components/SimpleModal";
import { Suspense } from "components/Suspense";

export type ConfirmationModalProps = {
  onClose: () => void;
  onSubmit: () => void;
  startTime: string | undefined;
};

export const ConfirmationModalContent = ({ onClose, onSubmit, startTime }: ConfirmationModalProps) => {
  return (
    <>
      <Heading size={18}>
        <FormattedMessage
          id="relationships.confirmProgramTermsSchedule"
          defaultMessage="Confirm Program Terms Schedule"
        />
      </Heading>
      <Stack gap="medium">
        <Row>
          <Text>
            <FormattedMessage
              id="relationships.programTermsScheduleStart"
              defaultMessage="The program terms schedule will activate for publishers on <b>{startTime}.</b>"
              values={{
                startTime,
                b: (chunks) => <Text weight="bold">{chunks}</Text>,
              }}
            />
          </Text>
        </Row>
        <Row>
          <Text>
            <FormattedMessage
              id="relationships.programTermScheduleBestPractice"
              defaultMessage="As a best practice, we recommend setting the schedule start date to begin no earlier than <b>7 days</b> from the current date and time, if the program terms contain any of the following changes:"
              values={{
                b: (chunks) => <Text weight="bold">{chunks}</Text>,
              }}
            />
          </Text>
        </Row>
        <Row>
          <ul>
            <li>
              <FormattedMessage id="relationships.commissionRateDecreases" defaultMessage="Commission rate decreases" />
            </li>
            <li>
              <FormattedMessage id="relationships.programPolicyChanges" defaultMessage="Program policy changes" />
            </li>
            <li>
              <FormattedMessage
                id="relationships.situationalCommissioning"
                defaultMessage="Situational commissioning"
              />
            </li>
            <li>
              <FormattedMessage id="relationships.itemLists" defaultMessage="Item Lists" />
            </li>
            <li>
              <FormattedMessage
                id="relationships.specialTermsAndConditions"
                defaultMessage="Special Terms and Conditions"
              />
            </li>
          </ul>
        </Row>
        <Row>
          <Stack>
            <Text weight="bold">
              <FormattedMessage id="relationships.importantNoteTitle" defaultMessage="Important Note:" />
            </Text>
            <Text>
              <FormattedMessage
                id="relationships.noteOfferSent"
                defaultMessage="When the offer is sent, the scheduled program terms will make the following changes for the publishers or program terms selected:"
              />
            </Text>
          </Stack>
        </Row>
        <Row>
          <ul>
            <li>
              <FormattedMessage
                id="relationships.sendPendingReplacement"
                defaultMessage="Send pending replacement program terms to your active publishers."
              />
            </li>
            <li>
              <FormattedMessage id="relationships.retractPendingOffers" defaultMessage="Retract any pending offers." />
            </li>
            <li>
              <FormattedMessage id="relationships.declineApplications" defaultMessage="Decline all new applications." />
            </li>
          </ul>
        </Row>
        <Row>
          <Text>
            <a href="http://cj.com" rel="noreferrer" target="_blank">
              <FormattedMessage
                id="relationships.learnMoreSupportCenter"
                defaultMessage="Learn more and see rules examples in the Support Center"
              />
            </a>
          </Text>
        </Row>
      </Stack>
      <Row justify="end" gap="medium-large">
        <Button type="secondary" onClick={onClose}>
          <FormattedMessage id="relationships.goBack" defaultMessage="Go Back" />
        </Button>
        <Button type="primary" onClick={onSubmit}>
          <FormattedMessage id="relationships.proceed" defaultMessage="Proceed" />
        </Button>
      </Row>
    </>
  );
};

export const ConfirmationModal = ({ ...props }: ConfirmationModalProps) => {
  return (
    <SimpleModal
      onBackgroundClick={props.onClose}
      body={
        <Suspense padding="xl" gap="xl">
          <ConfirmationModalContent onSubmit={props.onSubmit} startTime={props.startTime} onClose={props.onClose} />
        </Suspense>
      }
    ></SimpleModal>
  );
};
