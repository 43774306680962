import { useIntl } from "hooks";
import { ReactNode } from "react";
import { useScheduleEventsForm } from "../../../views/OfferProgramTerms/hooks/useScheduleEventsForm";

import { InputField } from "@cjdev-internal/visual-stack-x/InputField";

export type LabeledDescriptionPropTypes = {
  id: string;
  index: number;
  label: ReactNode;
  help?: ReactNode;
  value: string | null;
  required?: boolean;
  error?: string;
};

export const LabeledDescription = ({ id, index, label, help, value, required, error }: LabeledDescriptionPropTypes) => {
  const { formatMessage } = useIntl();
  const { updateScheduleEvents } = useScheduleEventsForm();
  return (
    <InputField
      id={id}
      help={help}
      htmlFor={id}
      label={label}
      required={required}
      error={error}
      placeholder={formatMessage({
        id: "relationships.descriptionPlaceholder",
        defaultMessage: "Enter Description",
      })}
      // @ts-ignore
      // CJPM-31774: We're purposefully ignoring the type checking here since VSX doesn't accept a null value
      // for this field
      value={value}
      onChange={(value: string) => updateScheduleEvents(index, "description", value)}
    />
  );
};
