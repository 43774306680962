import { useIntl as useNavIntl } from "hooks/useIntl";
import * as R from "ramda";
import { useCallback, useMemo } from "react";
import { Maybe } from "types";
import { ScheduleNameContext } from "views/OfferProgramTerms/context/ScheduleNameContext";
import { useProviderInitializedContext } from "hooks/useProviderInitializedContext";

export const useScheduleName = () => {
  const { scheduleName, setScheduleName, isScheduleNameTouched, setScheduleNameTouched, providerInitialized } =
    useProviderInitializedContext(ScheduleNameContext);

  const { formatMessage } = useNavIntl();

  const touchScheduleName = useCallback(() => {
    setScheduleNameTouched(true);
  }, [setScheduleNameTouched]);

  const isScheduleNameEmpty = R.isEmpty(scheduleName?.trim());

  const isScheduleNameTooLong = scheduleName?.length > 100;

  const isScheduleNameValid = !(isScheduleNameEmpty || isScheduleNameTooLong);

  const scheduleNameError: Maybe<string> = useMemo(() => {
    if (!isScheduleNameTouched) {
      return;
    }

    if (isScheduleNameEmpty) {
      return formatMessage({
        id: "relationships.scheduleNameRequired",
        defaultMessage: "Schedule Name is required.",
      });
    }

    if (isScheduleNameTooLong) {
      return formatMessage({
        id: "relationships.scheduleNameMaxLength",
        defaultMessage: "Schedule Name should not be more than 100 characters long.",
      });
    }
  }, [isScheduleNameTouched, formatMessage, isScheduleNameTooLong, isScheduleNameEmpty]);

  return {
    scheduleName,
    setScheduleName,
    isScheduleNameTouched,
    isScheduleNameValid,
    touchScheduleName,
    scheduleNameError,
    providerInitialized,
  };
};
