import { getMemberHost } from "config";
import Cookies from "js-cookie";

export const getAdvertiserIdFromUrl = (): number => {
  const params = new URLSearchParams(window.location.search);
  try {
    const paramsValue = params.get("advertiserId");
    return paramsValue ? parseInt(paramsValue) : 0;
  } catch (error) {
    return 0;
  }
};

export const getPublisherIdsFromUrl = (): Array<number> => {
  const params = new URLSearchParams(window.location.search);
  try {
    const paramsValue = params.get("publisherIds");
    return paramsValue ? paramsValue.split(",").map((id: string) => parseInt(id)) : [];
  } catch (error) {
    return [];
  }
};

export const getProgramTermsIdsToReplaceFromUrl = (): Array<number> => {
  const params = new URLSearchParams(window.location.search);
  try {
    const paramsValue = params.get("termsToReplace");
    return paramsValue ? paramsValue.split(",").map((id: string) => parseInt(id)) : [];
  } catch (error) {
    return [];
  }
};

export const navigateToReturnUrl = (): void => {
  const returnUrl = decodeURI(Cookies.get("returnUrl") || `https://${getMemberHost()}`);
  document.location = returnUrl;
};

export const redirectToLogin = () => {
  const appName = process.env.REACT_APP_APP_NAME || "relationships";
  const path = window.location.pathname + window.location.search;
  const pathQuery = path ? `&path=${encodeURIComponent(path)}` : "";
  window.location.assign(`https://${getMemberHost()}/member/login?appName=${appName}${pathQuery}`);
};

export const redirectToHome = (): void => {
  document.location = `https://${getMemberHost()}`;
};
