import { User } from "@platform/cj-platform-navigation/types/lib";
import { Company, Maybe, LegacyUser } from "types";

export enum COMPANY_TYPE {
  ADVERTISER = "advertiser",
  PUBLISHER = "publisher",
}

export const isAdvertiser = (user: User): boolean => user.currentCompany.type === COMPANY_TYPE.ADVERTISER;

export const currentCompanyId = (user: User): number => user.currentCompany.id;

export const isUserForAdvertiser = (advertiserId: number, user?: Maybe<LegacyUser>): boolean => {
  const associatedWithAdvertiser = (company: Company): boolean =>
    company.id === advertiserId && company.type === COMPANY_TYPE.ADVERTISER;
  return Boolean(user?.companies.find(associatedWithAdvertiser));
};
