import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Location } from "react-router";

type OAuthState = {
  companyId: number;
  redirectUrl: string;
};

const parseUrlHash = (location: Location): OAuthState => {
  const params = new URLSearchParams(location.hash.substring(1));
  const state = params.get("state") || "e30=";
  return JSON.parse(window.atob(state));
};

export const OAuthCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const { redirectUrl } = parseUrlHash(location);
    if (redirectUrl) {
      navigate(redirectUrl, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};
