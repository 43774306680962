import { zonedTimeToUtc } from "date-fns-tz";
import { ScheduleEvent, ScheduleEventFormEntry } from "localTypes";
import { formatIntlDateTimeString } from "./dateTimeUtils";

export const findFirstSpecialTermsId = (
  scheduleEvents: Array<ScheduleEventFormEntry>,
  specialTermsIdLookup: (programTermsId: number) => number
) => {
  const firstProgramId = (scheduleEvents[0]?.programTerms?.value ?? 0) as number;
  return specialTermsIdLookup(firstProgramId);
};

export const getFormattedEventStartTime = (scheduleEvent: ScheduleEvent, timeZone: string): string | undefined => {
  if (!scheduleEvent || !timeZone) return undefined;
  const eventStartTimeAsUtc = zonedTimeToUtc(scheduleEvent?.startTime, timeZone);
  return formatIntlDateTimeString(eventStartTimeAsUtc, timeZone);
};
