import { Stack } from "@cjdev-internal/visual-stack-x/Stack";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { FormattedMessage } from "../../../components/FormattedMessage";
import { useRolloverPeriod, useUser } from "hooks";
import { Heading } from "@cjdev-internal/visual-stack-x/Heading";
import { currentCompanyId } from "utils/authorizationUtils";

export const ProgramTermsReplacementRules = () => {
  const { user } = useUser();
  const rolloverPeriod = useRolloverPeriod(currentCompanyId(user));

  return (
    <>
      <Stack gap="medium">
        <Heading size={16}>
          <FormattedMessage
            id="relationships.programTermsReplacementRules.heading"
            defaultMessage="Program terms replacement rules"
          />
        </Heading>
        <Text>
          <FormattedMessage
            id="relationships.programTermsReplacementRules.fromTheTime"
            defaultMessage="From the time you push a replacement program terms in your timezone, they can go into effect as early as:"
          />
        </Text>
      </Stack>
      <ul>
        <li>
          <Text>
            <FormattedMessage
              id="relationships.programTermsReplacementRules.topUpcomingHour"
              defaultMessage="{title} when a higher commission rate is applied."
              values={{
                title: (
                  <Text weight="bold">
                    <FormattedMessage
                      id="relationships.programTermsReplacementRules.topUpcomingHourTitle"
                      defaultMessage="Top of the upcoming hour,"
                    />
                  </Text>
                ),
              }}
            />
          </Text>
        </li>
        <li>
          {rolloverPeriod === 24 ? (
            <FormattedMessage
              id="relationships.programTermsReplacementRules.24hours"
              defaultMessage="{title} when a lower commission rate, actions with Situational Commissioning, or changes to program term policies are applied."
              values={{
                title: (
                  <Text weight="bold">
                    <FormattedMessage
                      id="relationships.programTermsReplacementRules.24hoursTitle"
                      defaultMessage="24 hours,"
                    />
                  </Text>
                ),
              }}
            />
          ) : (
            <FormattedMessage
              id="relationships.programTermsReplacementRules.1hour"
              defaultMessage="{title} when a lower commission rate, actions with Situational Commissioning, or changes to program term policies are applied."
              values={{
                title: (
                  <Text weight="bold">
                    <FormattedMessage
                      id="relationships.programTermsReplacementRules.1hourTitle"
                      defaultMessage="1 hour,"
                    />
                  </Text>
                ),
              }}
            />
          )}
        </li>
        <li>
          <FormattedMessage
            id="relationships.programTermsReplacementRules.noEarlierThan7Days"
            defaultMessage="{title} when new Special Terms and Conditions are applied."
            values={{
              title: (
                <Text weight="bold">
                  <FormattedMessage
                    id="relationships.programTermsReplacementRules.noEarlierThan7DaysTitle"
                    defaultMessage="No earlier than 7 days,"
                  />
                </Text>
              ),
            }}
          />
          <ul>
            <li>
              <FormattedMessage
                id="relationships.programTermsReplacementRules.7DayNoticeRequired"
                defaultMessage="A 7 day advance notice and publisher acceptance is required."
              />
            </li>
          </ul>
        </li>
      </ul>

      <Text>
        <FormattedMessage
          id="relationships.programTermsReplacementRules.note"
          defaultMessage="Note: Check with your publishers for any specific changes to the start date requirements."
        />
      </Text>

      <Text>
        <FormattedMessage
          id="relationships.programTermsReplacementRules.relationshipsWillChange"
          defaultMessage="{title} the scheduled program terms will replace or decline/retract the current publisher program terms. Review how the publisher relationships will change below:"
          values={{
            title: (
              <Text weight="bold">
                <FormattedMessage
                  id="relationships.programTermsReplacementRules.relationshipsWillChangeTitle"
                  defaultMessage="When the offer is sent,"
                />
              </Text>
            ),
          }}
        />
      </Text>

      <ul>
        <li>
          <FormattedMessage
            id="relationships.programTermsReplacementRules.myPublishers"
            defaultMessage="{title} Your active publishers will receive new pending offers and the replacement terms will go into effect based on the rules and settings above."
            values={{
              title: (
                <Text weight="bold">
                  <FormattedMessage
                    id="relationships.programTermsReplacementRules.myPublishersTitle"
                    defaultMessage="My publishers:"
                  />
                </Text>
              ),
            }}
          />
        </li>
        <li>
          <FormattedMessage
            id="relationships.programTermsReplacementRules.pendingApplicationOrOffer"
            defaultMessage="{title} The application or offer will be declined/retracted and these publishers will be moved to declined Application or Declined Offer. We recommend you review all of your pending applications and offers before replacing program terms."
            values={{
              title: (
                <Text weight="bold">
                  <FormattedMessage
                    id="relationships.programTermsReplacementRules.pendingApplicationOrOfferTitle"
                    defaultMessage="Pending application or offer:"
                  />
                </Text>
              ),
            }}
          />
        </li>
        <li>
          <FormattedMessage
            id="relationships.programTermsReplacementRules.expiredOrDeclinedOffers"
            defaultMessage="{title} When program term replacements with Special Terms and Conditions are not accepted withing the 7 day acceptance window, the publishers are expired from the program. When publishers decline the program term replacement, the publishers are expired from the program."
            values={{
              title: (
                <Text weight="bold">
                  <FormattedMessage
                    id="relationships.programTermsReplacementRules.expiredOrDeclinedOffersTitle"
                    defaultMessage="Expired or declined offers:"
                  />
                </Text>
              ),
            }}
          />
        </li>
      </ul>
    </>
  );
};
