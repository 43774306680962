import { Suspense } from "components/Suspense";
import { IntlProvider, ModalProvider, QueryClientProvider } from "context";
import { ProviderProps } from "localTypes/ProviderTypes";

export const AppProvider = ({ children }: ProviderProps) => (
  <IntlProvider>
    <QueryClientProvider>
      <ModalProvider>
        <Suspense expand>{children}</Suspense>
      </ModalProvider>
    </QueryClientProvider>
  </IntlProvider>
);
