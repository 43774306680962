import { zonedTimeToUtc } from "date-fns-tz";
import { ScheduleEventFormEntry, ScheduleEventModel } from "localTypes";
import * as R from "ramda";
import { REVERT_TO_CURRENT_TERMS } from "views/OfferProgramTerms/context/OfferProgramTermsContext";
import { PostScheduleOfferPayload, ScheduleOfferPayloadRequiringOmission } from "../types";

const removeEmptyValues = R.reject(R.isEmpty);
const tooManyEntitiesSubmitted = R.pipe(R.keys, R.length, R.lt(1));
const pickOne = R.when(tooManyEntitiesSubmitted, R.omit(["replaceProgramTerms"]));
// TODO: remove this when we remove the LegacyOfferProgramTermsProvider
export const formatOfferToEntityForApi = R.pipe(removeEmptyValues, pickOne);

export const omitPublishersOrProgramTerms: (
  payload: ScheduleOfferPayloadRequiringOmission[]
) => PostScheduleOfferPayload = (payload) => {
  if (payload.length === 0) return payload;
  const f = payload[0].offerToPublishers.length > 0 ? R.omit(["replaceProgramTerms"]) : R.omit(["offerToPublishers"]);
  return payload.map(f);
};

export const startOfHourNow = () => new Date().toISOString().slice(0, 13) + ":00:00.000Z";

export const createScheduleEventModels = (
  scheduleEvents: ScheduleEventFormEntry[],
  allowEarlyAccept: boolean,
  timeZone: string,
  revertTo?: number
) =>
  scheduleEvents.map(
    (scheduleEvent, index) =>
      ({
        description: scheduleEvent.description,
        startDate:
          allowEarlyAccept && index === 0
            ? startOfHourNow()
            : zonedTimeToUtc(new Date(scheduleEvent.startTime), timeZone).toISOString(),
        programTermsId:
          scheduleEvent.programTerms?.value === REVERT_TO_CURRENT_TERMS ? revertTo : scheduleEvent.programTerms?.value,
      } as ScheduleEventModel)
  );
