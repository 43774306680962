import { css } from "@emotion/css";
import { Heading } from "@cjdev-internal/visual-stack-x/Heading";
import { Row } from "@cjdev-internal/visual-stack-x/Row";
import { Stack } from "@cjdev-internal/visual-stack-x/Stack";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { SimpleModal } from "components/SimpleModal";
import { Suspense } from "components/Suspense";
import CloseIcon from "mdi-react/CloseIcon";
import InfoCircleOutlineIcon from "mdi-react/InfoCircleOutlineIcon";
import { FormattedMessage } from "../../../components/FormattedMessage";
import { ProgramTermsReplacementRules } from "./ProgramTermsReplacementRules";

type SchedulingRulesModalProps = {
  onClose: () => void;
};

const SchedulingRulesModalContent = ({ ...props }: SchedulingRulesModalProps) => {
  return (
    <>
      <Heading size={18}>
        <Row justify="space-between">
          <FormattedMessage
            id="relationships.schedulingRulesModalHeading"
            defaultMessage="Scheduling best practices and rules"
          />
          <CloseIcon data-testid="cancel-link" onClick={props.onClose} />
        </Row>
      </Heading>

      <Row gap="medium">
        <Stack padding="xs">
          <InfoCircleOutlineIcon
            className={css`
              color: var(--color-critical);
            `}
            size={18}
          />
        </Stack>
        <Stack gap="medium">
          <Heading>
            <FormattedMessage id="relationships.avoidSchedulingErrors" defaultMessage="Avoid Scheduling Errors" />
          </Heading>
          <Text>
            <FormattedMessage
              id="relationships.reviewProgramTerms"
              defaultMessage="Review your program terms and set the schedule start date for the first program terms in the schedule to match the rules below. These rules only apply to the first program terms in the schedule."
            />
          </Text>
        </Stack>
      </Row>

      <ProgramTermsReplacementRules />
    </>
  );
};

export const SchedulingRulesModal = ({ ...props }: SchedulingRulesModalProps) => {
  return (
    <SimpleModal
      onBackgroundClick={props.onClose}
      body={
        <>
          <Suspense padding="xl" gap="xl">
            <SchedulingRulesModalContent onClose={props.onClose} />
          </Suspense>
        </>
      }
    />
  );
};
