import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export const ErrorText = ({ children }: Props) => (
  <Text size={12} color={"critical"}>
    {children}
  </Text>
);
