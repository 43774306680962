import React from "react";
import { useNav } from "hooks";
import { FormattedMessage as IntlFormattedMessage } from "react-intl";
import { FormattedMessage as PlatformFormattedMessage } from "@platform/cj-platform-navigation";
import { PrimitiveType, FormatXMLElementFn } from "intl-messageformat";

type FormattedMessageValuesProp = Record<
  string,
  React.ReactNode | PrimitiveType | FormatXMLElementFn<React.ReactNode, React.ReactNode>
>;

export type FormattedMessageProps = {
  id: string;
  defaultMessage: string;
  values?: FormattedMessageValuesProp;
};

export const FormattedMessage = ({ id, defaultMessage, values }: FormattedMessageProps) => {
  const { use2022Nav } = useNav();
  return use2022Nav ? (
    <PlatformFormattedMessage id={id} defaultMessage={defaultMessage} values={values} />
  ) : (
    <IntlFormattedMessage id={id} defaultMessage={defaultMessage} values={values} />
  );
};
