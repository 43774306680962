import { ErrorPage } from "@cjdev-internal/visual-stack-x/components/ErrorPage";
import { useUser } from "hooks";
import { ReactElement } from "react";
import { Authorizer } from "./authorizers";

export type AuthorizationProps = {
  authorizer: Authorizer;
  children: ReactElement;
};

export const Authorization = ({ authorizer, children }: AuthorizationProps) => {
  const { user } = useUser();

  if (!user || Object.keys(user).length === 0) {
    return null;
  }

  return authorizer(user) ? children : <ErrorPage errorCode={403} />;
};
