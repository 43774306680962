import { Stack, StackProps } from "@cjdev-internal/visual-stack-x/Stack";
import { Spinner } from "@cjdev-internal/visual-stack-x/Spinner";
import { ErrorBoundary } from "./ErrorBoundary";
import React, { ReactNode } from "react";

export type SuspenseProps = StackProps & {
  children?: ReactNode;
};

export const Suspense = ({ children, ...restProps }: SuspenseProps) => (
  <Stack {...restProps}>
    <React.Suspense
      fallback={
        <Stack expand align="center" justify="center">
          <Spinner />
        </Stack>
      }
    >
      <ErrorBoundary>{children}</ErrorBoundary>
    </React.Suspense>
  </Stack>
);
