import { getMemberHost } from "config";
import { Maybe, LegacyUser } from "types";
import { getUserId } from "utils/platformAuth";
import { authenticatedFetch } from "./authenticatedFetch";

export const fetchUser = async (): Promise<Maybe<LegacyUser>> => {
  const userId = await getUserId();
  if (!userId) {
    return null;
  }
  return await authenticatedFetch<LegacyUser>(`https://${getMemberHost()}/affapi/oauth/user/${userId}`);
};
