export const isProduction = () =>
  window.location.host === "relationships.cj.com" || window.location.host === "relationships.prod.cj.com";

export const getMemberHost = (): string => {
  return isProduction() ? "members.cj.com" : "member106.lab.cj.com";
};

// prettier-ignore
const apiHostMap = {
  "relationships.cj.com"               : "relationships.backend.cj.com",
  "relationships.prod.cj.com"          : "relationships.backend.prod.cj.com",
  "relationships.lab.cj.com"           : "relationships.backend.lab.cj.com",
  "relationships.sandbox.cj.com"       : "relationships.backend.sandbox.cj.com",
  "local.cj.com"                       : "local.cj.com:4000",
  "localhost"                          : "localhost",
};

type ApiHostKey = keyof typeof apiHostMap;

export const getApiHost = (): string => {
  const apiHost: string | undefined = apiHostMap[window.location.host as ApiHostKey];
  if (!apiHost) {
    throw new Error(`Unexpected host. ${window.location.host}`);
  }
  return apiHost;
};

export const getAccountsApiHost = (): string => {
  return isProduction() ? "accounts.api.cj.com" : "accounts-api.elbodev.cjpowered.com";
};
