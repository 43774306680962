import cs from "./cs.json";
import de from "./de.json";
import es from "./es.json";
import fr from "./fr.json";
import ja from "./ja.json";
import pl from "./pl.json";
import pt from "./pt.json";
import zh from "./zh.json";

const translations = { cs, de, es, fr, ja, pl, pt, zh };
export default translations;
