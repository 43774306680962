import { ScheduleEventFormEntry } from "localTypes";

const padDateNumber = (num: number) => num.toString().padStart(2, "0");

export const dateTimeLocalString = (date: Date) => {
  const year = date.getFullYear();
  const month = padDateNumber(date.getMonth() + 1);
  const day = padDateNumber(date.getDate());
  const hour = padDateNumber(date.getHours());
  const minute = padDateNumber(date.getMinutes());
  return `${year}-${month}-${day}T${hour}:${minute}`;
};

export const roundUpToNextHour = (date: Date) => {
  date.setHours(date.getHours() + Math.ceil(date.getMinutes() / 60));
  date.setMinutes(0);
  return date;
};

export const addRolloverPeriod = (date: Date): void => {
  date.setDate(date.getDate() + 7);
};

export const createNewStartTime = (date: Date): string => {
  roundUpToNextHour(date);
  addRolloverPeriod(date);
  return dateTimeLocalString(date);
};

export const addHoursToDate = (date: Date, hours: number): Date => {
  const newDateObj = new Date(date);
  roundUpToNextHour(newDateObj);
  newDateObj.setHours(newDateObj.getHours() + hours);
  newDateObj.setSeconds(0);
  return newDateObj;
};

export const generateMinDateTime = (index: number, newMinDateTime: Date): Date =>
  addHoursToDate(newMinDateTime, index === 0 ? 0 : index);

export const isTimeZoneError = (minDateTime: Date, scheduleEventStartTime: string): boolean =>
  minDateTime >= addHoursToDate(new Date(scheduleEventStartTime), 1) ? true : false;

export const isStartTimeError = (
  index: number,
  scheduleEvent: ScheduleEventFormEntry,
  scheduleEvents: Array<ScheduleEventFormEntry>
): boolean =>
  scheduleEvent.startTime >= scheduleEvents[index + 1]?.startTime ||
  scheduleEvent.startTime <= scheduleEvents[index - 1]?.startTime
    ? true
    : false;

export const validateStartTimes = (scheduleEvents: Array<ScheduleEventFormEntry>): Array<ScheduleEventFormEntry> => {
  return scheduleEvents.map((scheduleEvent, i, scheduleEvents) => {
    return {
      ...scheduleEvent,
      errors: {
        ...scheduleEvent.errors,
        timeZone: isTimeZoneError(scheduleEvent.minDateTime, scheduleEvent.startTime),
        startTime: isStartTimeError(i, scheduleEvent, scheduleEvents),
      },
    };
  });
};

export const formatIntlDateTimeString = (date: Date, timeZone: string): string =>
  Intl.DateTimeFormat(navigator.language, {
    timeZone: timeZone,
    day: "numeric",
    month: "short",
    year: "numeric",
    weekday: "short",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  }).format(date);
