import { createContext, useState } from "react";
import { ProviderProps } from "localTypes/ProviderTypes";

export type OfferOptionsContextType = {
  allowEarlyAccept: boolean;
  setAllowEarlyAccept: React.Dispatch<React.SetStateAction<boolean>>;
  providerInitialized: boolean;
};

export const OfferOptionsContext = createContext<OfferOptionsContextType>({
  allowEarlyAccept: true,
  setAllowEarlyAccept: () => {},
  providerInitialized: false,
} as OfferOptionsContextType);

export const OfferOptionsProvider = ({ children }: ProviderProps) => {
  const [allowEarlyAccept, setAllowEarlyAccept] = useState<boolean>(true);

  return (
    <OfferOptionsContext.Provider
      value={{
        allowEarlyAccept,
        setAllowEarlyAccept,
        providerInitialized: true,
      }}
    >
      {children}
    </OfferOptionsContext.Provider>
  );
};
