import { fetchGenerateSignUpLinks } from "api/fetchInfluencerSignupLinks";
import { SignupLinksResponse } from "types";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { DropDownSelectorOption } from "components/DropDownSelector";
import { formatCreatorIdsForApi } from "views/BrandedSignupLinks/utils";

type CSVdata = Array<SignupLinksResponse>;

export type ExportSignupLinksToCsvProps = {
  programTerms: DropDownSelectorOption | undefined;
  creatorIds: string;
};

const exportToCSV = (csvData: CSVdata, fileName: string) => {
  const ws = XLSX.utils.json_to_sheet(csvData);
  const csv = XLSX.utils.sheet_to_csv(ws);
  const data = new Blob([csv]);
  FileSaver.saveAs(data, `${fileName}.csv`);
};

export const exportSignupLinksToCSV = async ({ programTerms, creatorIds }: ExportSignupLinksToCsvProps) => {
  const linksData = await fetchGenerateSignUpLinks(programTerms, formatCreatorIdsForApi(creatorIds));
  return exportToCSV(linksData, "signupLinks");
};
