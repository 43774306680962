import { createContext, Dispatch, SetStateAction, useState } from "react";
import { ProviderProps } from "localTypes/ProviderTypes";
import { addHoursToDate, createNewStartTime } from "utils/dateTimeUtils";
import { ScheduleEventFormEntry } from "localTypes";
import { useProviderInitializedContext } from "../../../hooks/useProviderInitializedContext";

export type ScheduleEventsContextType = {
  scheduleEvents: Array<ScheduleEventFormEntry>;
  setScheduleEvents: Dispatch<SetStateAction<ScheduleEventFormEntry[]>>;
  checkValidation: boolean;
  setCheckValidation: Dispatch<SetStateAction<boolean>>;
  providerInitialized: boolean;
};

export const ScheduleEventsContext = createContext<ScheduleEventsContextType>({
  scheduleEvents: [],
  setScheduleEvents: () => {},
  checkValidation: false,
  setCheckValidation: () => {},
  providerInitialized: false,
} as ScheduleEventsContextType);
export const useScheduleEventsContextRetriever = () => useProviderInitializedContext(ScheduleEventsContext);

export const ScheduleEventsProvider = ({ children }: ProviderProps) => {
  const defaultScheduleEvent: ScheduleEventFormEntry = {
    programTerms: undefined,
    startTime: new Date(createNewStartTime(new Date())).toISOString(),
    description: null,
    minDateTime: new Date(addHoursToDate(new Date(), 0).toISOString()),
    errors: {
      startTime: false,
      timeZone: false,
    },
  };

  const [scheduleEvents, setScheduleEvents] = useState<Array<ScheduleEventFormEntry>>([defaultScheduleEvent]);
  const [checkValidation, setCheckValidation] = useState(false);

  return (
    <ScheduleEventsContext.Provider
      value={{
        scheduleEvents,
        setScheduleEvents,
        checkValidation,
        setCheckValidation,
        providerInitialized: true,
      }}
    >
      {children}
    </ScheduleEventsContext.Provider>
  );
};
