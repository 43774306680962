import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Heading } from "@cjdev-internal/visual-stack-x/Heading";
import { IntlProvider } from "context";
import { ReactNode } from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { FormattedMessage } from "react-intl";

export type ErrorBoundaryProps = {
  children?: ReactNode;
};

const ErrorFallback = () => (
  <IntlProvider>
    <Box expand align="center" justify="center">
      <Heading>
        <FormattedMessage id="genericError" defaultMessage="Something went wrong." />
      </Heading>
    </Box>
  </IntlProvider>
);

export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => (
  <ReactErrorBoundary FallbackComponent={ErrorFallback}>{children}</ReactErrorBoundary>
);
