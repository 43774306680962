import { getMemberHost } from "config";
import { Maybe, RelationshipStatus } from "types";
import { getPublisherIdsFromUrl } from "utils/urlUtils";
import { authenticatedFetch } from "./authenticatedFetch";

export const fetchRelationshipStatuses = async (advertiserId: number): Promise<Maybe<RelationshipStatus[]>> => {
  const publisherIds = getPublisherIdsFromUrl()?.join(",");

  return await authenticatedFetch<RelationshipStatus[]>(
    `https://${getMemberHost()}/affapi/relationshipStatus?advertiserIds=${advertiserId}&publisherIds=${publisherIds}`
  );
};
