import React from "react";
import ReactDOM from "react-dom/client";
import "styles/global.css";
import "@cjdev-internal/design-tokens/design-tokens/css/variables.css";
import { shouldDisplay2022Nav } from "@platform/cj-platform-navigation";
import { App, AppWithNav } from "./App";
import { AppProvider } from "context";
import { PlatformNavProvider } from "context/PlatformNavContext";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const url = new URL(window.location.href);

if (url.pathname === "/health") {
  root.render(<div>Feelin good.</div>);
} else {
  shouldDisplay2022Nav().then((display2022Nav: boolean) => {
    display2022Nav
      ? root.render(
          <React.StrictMode>
            <PlatformNavProvider use2022Nav>
              <AppProvider>
                <AppWithNav />
              </AppProvider>
            </PlatformNavProvider>
          </React.StrictMode>
        )
      : root.render(
          <React.StrictMode>
            <PlatformNavProvider use2022Nav={false}>
              <AppProvider>
                <App />
              </AppProvider>
            </PlatformNavProvider>
          </React.StrictMode>
        );
  });
}
