import React from "react";
import { Panel, Body } from "@cjdev-internal/visual-stack-x/Panel";

type PageLayoutProps = {
  children: React.ReactNode;
};

export const PageLayout = ({ children }: PageLayoutProps) => (
  <>
    <Panel>
      <Body>{children}</Body>
    </Panel>
  </>
);
