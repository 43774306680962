import { findFirstSpecialTermsId } from "utils/scheduleEventUtils";
import * as R from "ramda";
import { useIntl, useUser } from "hooks";
import { useActiveProgramTermsOptions } from "views/OfferProgramTerms/hooks";
import { ScheduleEventFormEntry } from "localTypes";
import { useScheduleEventsContextRetriever } from "../context/ScheduleEventsContext";
import { currentCompanyId } from "utils/authorizationUtils";

export const useValidateSpecialTerms = () => {
  const { scheduleEvents } = useScheduleEventsContextRetriever();
  const { user } = useUser();
  const { specialTermsIdLookup } = useActiveProgramTermsOptions(currentCompanyId(user));
  const intl = useIntl();

  const validateSpecialTerms = (scheduleEvent: ScheduleEventFormEntry) => {
    const programTermsId = R.path(["programTerms", "value"], scheduleEvent) as number;
    const specialTermsId = specialTermsIdLookup(programTermsId);
    const firstSpecialTermsId = findFirstSpecialTermsId(scheduleEvents, specialTermsIdLookup);
    const result = firstSpecialTermsId === specialTermsId || specialTermsId === 0;
    return result;
  };

  const validateSpecialTermsForSchedule = R.map(
    R.ifElse(
      validateSpecialTerms,
      R.assocPath(["errors", "programTerms"], undefined),
      R.assocPath(
        ["errors", "programTerms"],
        intl.formatMessage({
          id: "relationships.specialTermsDoNotMatchErrorMessage",
          defaultMessage: "Program terms must contain the same Special Ts and Cs as first scheduled program terms.",
        })
      )
    )
  );

  return {
    validateSpecialTermsForSchedule,
  };
};
