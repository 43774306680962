import { Field } from "@cjdev-internal/visual-stack-x/Field";

import { isEqual, startOfHour, startOfDay } from "date-fns";
import { UpdateMultipleEntityFormHandler } from "localTypes";
import { ReactNode } from "react";
import "./index.css";
import { DateInput, useDateInput } from "@cjdev-internal/visual-stack-x/DateInput";
import { ErrorText } from "../../ErrorText";
import { FormattedMessage } from "../../FormattedMessage";

export type LabeledDateHourSelectorPropTypes = {
  id: string;
  index: number;
  label: ReactNode;
  startTime: string;
  updateHandler: UpdateMultipleEntityFormHandler;
  required?: boolean;
  minDate?: Date;
  minDateTime?: Date;
  help?: ReactNode;
  startTimeError?: boolean;
  timeZoneError?: boolean;
};

export const LabeledDateHourSelector = ({
  id,
  index,
  label,
  startTime,
  updateHandler,
  required,
  minDateTime,
  help,
  startTimeError,
  timeZoneError,
}: LabeledDateHourSelectorPropTypes) => {
  const startTimeAsDate = startOfHour(new Date(startTime));
  const updateStartTime = (date?: Date): void => {
    updateHandler(index, "startTime", date ? date.toISOString() : "");
  };
  const dateInput = useDateInput({
    startDate: startTimeAsDate,
    onChange: updateStartTime,
    transform: startOfHour,
  });
  const startOfDayMinDateTime = startOfDay(minDateTime || new Date());
  const startOfDaySelectedDate = startOfDay(dateInput.date || startTimeAsDate);
  return (
    <Field
      error={
        timeZoneError || startTimeError ? (
          <>
            {timeZoneError && (
              <>
                <ErrorText>
                  <FormattedMessage
                    id="relationships.timeZoneError"
                    defaultMessage="Start date and time must be in the future."
                  />
                </ErrorText>
                <br />
              </>
            )}
            {startTimeError && (
              <ErrorText>
                <FormattedMessage
                  id="relationships.startTimeError"
                  defaultMessage="Start date and time must be after the previous and before the next program terms."
                />
              </ErrorText>
            )}
          </>
        ) : undefined
      }
      help={help}
      labelFor={id}
      label={label}
      required={required}
    >
      <DateInput
        {...dateInput}
        id={id}
        autoClose={false}
        clearable={false}
        dateFormat={"MM/dd/yyyy, h:mm a"}
        error={timeZoneError || startTimeError}
        datePickerProps={{
          showTimePicker: true,
          timeInterval: 60,
          locale: "en", // TODO
          disabledDay: (date) => date < startOfDayMinDateTime,
          disabledTime: (time) =>
            isEqual(startOfDayMinDateTime, startOfDaySelectedDate) && time.hours < (minDateTime?.getHours() || 0),
        }}
      />
    </Field>
  );
};
