import { getFormattedEventStartTime } from "utils/scheduleEventUtils";
import { ConfirmationModal } from "views/OfferProgramTerms/components/ConfirmationModal";
import { ConfirmationResultsModal } from "views/OfferProgramTerms/components/ConfirmationResultsModal";
import { ProcessingModal } from "views/OfferProgramTerms/components/ProcessingModal";
import { SchedulingRulesModal } from "views/OfferProgramTerms/components/SchedulingRulesModal";
import { useScheduleEventsForm, useTimeZone } from "views/OfferProgramTerms/hooks";
import { useModalContext } from "./useModalContext";

export const useModal = () => {
  const { mount, openModal, closeModal } = useModalContext();
  const { scheduleEvents } = useScheduleEventsForm();
  const { timeZone } = useTimeZone();

  const confirmationModalStartTime = getFormattedEventStartTime(scheduleEvents[0], timeZone.value as string);

  const openSchedulingRulesModal = (): void => openModal(<SchedulingRulesModal onClose={closeModal} />);

  const openConfirmationModal = (onSubmitHandler: () => Promise<void>): void =>
    openModal(
      <ConfirmationModal onClose={closeModal} onSubmit={onSubmitHandler} startTime={confirmationModalStartTime} />
    );

  const openProcessingModal = (): void => openModal(<ProcessingModal />);

  const openConfirmationResultsModal = (): void => openModal(<ConfirmationResultsModal onClose={closeModal} />);

  return {
    mount,
    openModal,
    closeModal,
    openSchedulingRulesModal,
    openProcessingModal,
    openConfirmationModal,
    openConfirmationResultsModal,
  };
};
