import { createContext, Dispatch, SetStateAction, useState } from "react";
import { ProviderProps } from "localTypes/ProviderTypes";
import { useProviderInitializedContext } from "hooks/useProviderInitializedContext";

export type ShowReplacementRulesContextType = {
  showReplacementRules: boolean;
  setShowReplacementRules: Dispatch<SetStateAction<boolean>>;
  providerInitialized: boolean;
};

export const ShowReplacementRulesContext = createContext<ShowReplacementRulesContextType>({
  showReplacementRules: false,
  setShowReplacementRules: () => {},
  providerInitialized: false,
} as ShowReplacementRulesContextType);
export const useShowReplacementRulesContextRetriever = () => useProviderInitializedContext(ShowReplacementRulesContext);

export const ShowReplacementRulesProvider = ({ children }: ProviderProps) => {
  const [showReplacementRules, setShowReplacementRules] = useState<boolean>(false);

  return (
    <ShowReplacementRulesContext.Provider
      value={{
        showReplacementRules,
        setShowReplacementRules,
        providerInitialized: true,
      }}
    >
      {children}
    </ShowReplacementRulesContext.Provider>
  );
};
