import { take, toLower } from "ramda";
import { ReactNode, useEffect, useState } from "react";
import { IntlProvider as ReactIntlProvider } from "react-intl";

export type IntlProviderProps = {
  children: ReactNode;
};

export const IntlProvider = ({ children }: IntlProviderProps) => {
  const locale = toLower(take(2, navigator.language));
  const messages = useMessages(locale);

  return (
    <ReactIntlProvider locale={locale} messages={messages}>
      {children}
    </ReactIntlProvider>
  );
};

const useMessages = (locale: string) => {
  const [messages, setMessages] = useState<Record<string, string>>();

  useEffect(() => {
    if (locale !== "en") {
      importMessages(locale).then(setMessages);
    }
  }, [locale]);

  return messages;
};

const importMessages = async (locale: string): Promise<Record<string, string>> => {
  switch (locale) {
    case "cs":
      return import("../translations/cs.json") as unknown as Promise<Record<string, string>>;
    case "de":
      return import("../translations/de.json") as unknown as Promise<Record<string, string>>;
    case "es":
      return import("../translations/es.json") as unknown as Promise<Record<string, string>>;
    case "fr":
      return import("../translations/fr.json") as unknown as Promise<Record<string, string>>;
    case "ja":
      return import("../translations/ja.json") as unknown as Promise<Record<string, string>>;
    case "pl":
      return import("../translations/pl.json") as unknown as Promise<Record<string, string>>;
    case "pt":
      return import("../translations/pt.json") as unknown as Promise<Record<string, string>>;
    case "zh":
      return import(`../translations/zh.json`) as unknown as Promise<Record<string, string>>;
    default:
      return await {};
  }
};
