import { ProviderProps } from "../localTypes/ProviderTypes";
import { UserProvider } from "context/UserContext";
import { useLegacyUser } from "hooks";
import { standardizeUser } from "utils/legacyUserUtils";
import { User } from "@platform/cj-platform-navigation/types/lib";

export const LegacyViewProvider = ({ children }: ProviderProps) => {
  const legacyUser = useLegacyUser();
  const user: User = standardizeUser(legacyUser);

  return <UserProvider user={user || {}}>{children}</UserProvider>;
};
