import { createContext, Dispatch, SetStateAction, useState } from "react";
import { ProviderProps } from "localTypes/ProviderTypes";
import { RelationshipStatus } from "types";
import { useProviderInitializedContext } from "../hooks/useProviderInitializedContext";

type RelationshipStatusType = RelationshipStatus[] | undefined;

export type RelationshipStatusesContextType = {
  relationshipStatuses: RelationshipStatusType;
  setRelationshipStatuses: Dispatch<SetStateAction<RelationshipStatusType>>;
  providerInitialized: boolean;
};

export const RelationshipStatusesContext = createContext<RelationshipStatusesContextType>({
  relationshipStatuses: undefined,
  setRelationshipStatuses: () => {},
  providerInitialized: false,
} as RelationshipStatusesContextType);
export const useRelationshipStatusesContextRetriever = (): RelationshipStatusesContextType =>
  useProviderInitializedContext(RelationshipStatusesContext);

export const RelationshipStatusesProvider = ({ children }: ProviderProps) => {
  const [relationshipStatuses, setRelationshipStatuses] = useState<RelationshipStatusType>(undefined);

  return (
    <RelationshipStatusesContext.Provider
      value={{
        relationshipStatuses,
        setRelationshipStatuses,
        providerInitialized: true,
      }}
    >
      {children}
    </RelationshipStatusesContext.Provider>
  );
};
