import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { LoadingAnimation } from "@cjdev-internal/visual-stack-x/LoadingAnimation";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { FormattedMessage } from "../../../components/FormattedMessage";
import { SimpleModal } from "components/SimpleModal";
import { useFeatures, useUser } from "hooks";
import { currentCompanyId } from "utils/authorizationUtils";

export const ProcessingModalContent = () => {
  const { user } = useUser();
  const { hasFeature } = useFeatures(currentCompanyId(user));
  const hasPTReversionFeature = hasFeature("PT_SCHEDULING_REVERT_SUPPORT");

  return (
    <Box padding="4xl">
      <Box padding="large" align="center">
        <LoadingAnimation
          loadingMessage={
            <FormattedMessage id="relationships.processingModal.processing" defaultMessage="Processing" />
          }
        />
      </Box>
      <Box paddingBottom="medium">
        <Text weight="bold" size={16} style={{ textAlign: "center" }}>
          <FormattedMessage
            id="relationships.processingModal.processingYourRequest"
            defaultMessage="Processing your program terms request"
          />
        </Text>
      </Box>
      <Box>
        {!hasPTReversionFeature ? (
          <Text style={{ textAlign: "center" }}>
            <FormattedMessage
              id="relationships.processingModal.doNotNavigateAway"
              defaultMessage="This may take a few minutes. Please do not navigate away from this screen."
            />
          </Text>
        ) : (
          <Text style={{ textAlign: "center" }}>
            <FormattedMessage
              id="relationships.processingModal.doNotNavigateAwayWithReversion"
              defaultMessage="This could take a few minutes or up to an hour. If you navigate away from this screen, the offer will still be processed. You won't need to make the same offer again."
            />
          </Text>
        )}
      </Box>
    </Box>
  );
};

export const ProcessingModal = () => {
  return <SimpleModal onBackgroundClick={() => undefined} body={<ProcessingModalContent />} />;
};
