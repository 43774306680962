import { createContext, Dispatch, SetStateAction, useState } from "react";
import { ProviderProps } from "localTypes/ProviderTypes";

export type ScheduleNameContextType = {
  scheduleName: string;
  setScheduleName: Dispatch<SetStateAction<string>>;
  isScheduleNameTouched: boolean;
  setScheduleNameTouched: Dispatch<SetStateAction<boolean>>;
  providerInitialized: boolean;
};

export const ScheduleNameContext = createContext<ScheduleNameContextType>({
  scheduleName: "",
  setScheduleName: () => {},
  isScheduleNameTouched: false,
  setScheduleNameTouched: () => {},
  providerInitialized: false,
} as ScheduleNameContextType);

export const ScheduleNameProvider = ({ children }: ProviderProps) => {
  const [scheduleName, setScheduleName] = useState("");
  const [isScheduleNameTouched, setScheduleNameTouched] = useState(false);

  return (
    <ScheduleNameContext.Provider
      value={{
        scheduleName,
        setScheduleName,
        isScheduleNameTouched,
        setScheduleNameTouched,
        providerInitialized: true,
      }}
    >
      {children}
    </ScheduleNameContext.Provider>
  );
};
