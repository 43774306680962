import { useQuery } from "@tanstack/react-query";
import { fetchUser } from "api/fetchUser";
import { useEffect } from "react";
import { LegacyUser } from "types";
import { redirectToLogin } from "utils/urlUtils";

export const useLegacyUser = (): LegacyUser => {
  const { data: user } = useQuery(["user"], async () => await fetchUser(), {
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  useEffect(() => {
    if (!user) {
      redirectToLogin();
    }
  }, [user]);

  return user as LegacyUser;
};
