import { getApiHost } from "config";
import { gql } from "graphql-request";
import { CreateProgramTermsScheduleInput, CreateProgramTermsScheduleResponse } from "types";
import { isEmpty } from "utils";
import { authenticatedFetch } from "./authenticatedFetch";

export const CREATE_PROGRAM_TERMS_SCHEDULE_MUTATION_GQL = gql`
  mutation createProgramTermsSchedule($input: CreateProgramTermsScheduleInput!) {
    createProgramTermsSchedule(input: $input) {
      errors {
        message
      }
      schedule {
        id
      }
    }
  }
`;

export const createProgramTermsSchedule = async (input: CreateProgramTermsScheduleInput) => {
  const response = await authenticatedFetch<CreateProgramTermsScheduleResponse>(`https://${getApiHost()}/graphql`, {
    method: "POST",
    credentials: "include",
    mode: "cors",
    body: JSON.stringify({
      query: CREATE_PROGRAM_TERMS_SCHEDULE_MUTATION_GQL,
      variables: {
        input,
      },
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!isEmpty(response.errors)) {
    throw response.errors;
  }

  if (!isEmpty(response.data?.createProgramTermsSchedule.errors)) {
    throw response.data?.createProgramTermsSchedule.errors;
  }

  return response.data!.createProgramTermsSchedule.schedule!;
};
