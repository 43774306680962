const isNumber = (s: string) => /^[0-9]+$/.test(s);

const splitAndTrim = (str: string) => {
  const tokens = str.split(",").map((s) => s.trim());
  // empty last token is ok (trailing space/comma), ignore them
  const last = tokens.slice(-1)[0];
  if ("" === last) {
    tokens.pop();
  }
  return tokens;
};

export const validateCreatorIds = (str: string) => {
  const tokens = splitAndTrim(str);
  return tokens.length > 0 && tokens.every(isNumber);
};

export const formatCreatorIdsForApi = (str: string) => {
  const tokens = splitAndTrim(str);
  return tokens.join(",");
};
