import { DropDownSelectorOption } from "components/DropDownSelector";
import { ScheduleEventFormEntry } from "localTypes";
import { useRef } from "react";
import { AlertContext } from "views/OfferProgramTerms/context/AlertContext";
import { useTimeZone } from "./useTimeZone";
import { useProviderInitializedContext } from "hooks/useProviderInitializedContext";

export const useAlert = () => {
  const {
    startTimesErrors,
    setStartTimeErrors,
    showFormErrorsAlert,
    setShowFormErrorsAlert,
    showTimeZoneAlert,
    setShowTimeZoneAlert,
    providerInitialized,
  } = useProviderInitializedContext(AlertContext);
  const { timeZone } = useTimeZone();
  const timeZoneRef = useRef(timeZone);

  const evaluateScheduleEventsAlert = (scheduleEvents: Array<ScheduleEventFormEntry>): void => {
    setStartTimeErrors(scheduleEvents.some((e) => e.errors.startTime || e.errors.timeZone));
  };

  const evaluateTimeZoneAlert = (timeZone: DropDownSelectorOption): void => {
    if (timeZoneRef.current !== timeZone) {
      timeZoneRef.current = timeZone;
      setShowTimeZoneAlert(true);
    }
  };

  return {
    startTimesErrors,
    showFormErrorsAlert,
    setShowFormErrorsAlert,
    showTimeZoneAlert,
    setShowTimeZoneAlert,
    evaluateScheduleEventsAlert,
    evaluateTimeZoneAlert,
    providerInitialized,
  };
};
