import { createContext, Dispatch, SetStateAction, useState } from "react";
import { ProviderProps } from "localTypes/ProviderTypes";
import { DropDownSelectorOption } from "components/DropDownSelector";
import { determineTimeZone, timeZoneOptions } from "utils/timeZoneUtils";

export type TimeZoneContextType = {
  timeZone: DropDownSelectorOption;
  setTimeZone: Dispatch<SetStateAction<DropDownSelectorOption>>;
  providerInitialized: boolean;
};

export const TimeZoneContext = createContext<TimeZoneContextType>({
  timeZone: {} as DropDownSelectorOption,
  setTimeZone: () => {},
  providerInitialized: false,
} as TimeZoneContextType);

export const TimeZoneProvider = ({ children }: ProviderProps) => {
  const browserTimeZone = determineTimeZone();
  const [timeZone, setTimeZone] = useState<DropDownSelectorOption>(browserTimeZone || timeZoneOptions[0]);

  return (
    <TimeZoneContext.Provider
      value={{
        timeZone,
        setTimeZone,
        providerInitialized: true,
      }}
    >
      {children}
    </TimeZoneContext.Provider>
  );
};
