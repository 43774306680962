import { useEffect } from "react";
import * as R from "ramda";
import { createNewStartTime, validateStartTimes, addHoursToDate, generateMinDateTime } from "utils/dateTimeUtils";
import { DropDownSelectorOption } from "components/DropDownSelector";
import { useValidateSpecialTerms } from "./useValidateSpecialTerms";
import { utcToZonedTime } from "date-fns-tz";
import { useValidateScheduleEventDescription } from "./useValidateScheduleEventDescription";
import { useTimeZone } from "views/OfferProgramTerms/hooks";
import { useScheduleEventsContextRetriever } from "../context/ScheduleEventsContext";
import { ScheduleEventFormEntry, UpdateMultipleEntityFormHandler } from "localTypes";

export const useScheduleEventsForm = () => {
  const { timeZone: selectedtimeZone } = useTimeZone();
  const { validateScheduleEventDescriptions } = useValidateScheduleEventDescription();

  const { scheduleEvents, setScheduleEvents, checkValidation, setCheckValidation } =
    useScheduleEventsContextRetriever();

  const { validateSpecialTermsForSchedule } = useValidateSpecialTerms();

  useEffect(() => {
    const tzMinDateTime = addHoursToDate(utcToZonedTime(new Date(), selectedtimeZone.value as string), 0);
    const tzScheduleEvents: Array<ScheduleEventFormEntry> = scheduleEvents.map(
      (scheduleEvent: ScheduleEventFormEntry, i: number) => {
        return {
          ...scheduleEvent,
          minDateTime: generateMinDateTime(i, tzMinDateTime),
        };
      }
    );
    const validatedScheduleEvents = validateStartTimes(tzScheduleEvents);
    setScheduleEvents(validatedScheduleEvents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedtimeZone]);

  useEffect(() => {
    setCheckValidation(false);
    setScheduleEvents((oldScheduleEvents: Array<ScheduleEventFormEntry>) =>
      validateSpecialTermsForSchedule(validateScheduleEventDescriptions(oldScheduleEvents))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidation]);

  const addScheduleEvent = (): void => {
    const previousScheduleEvent = scheduleEvents[scheduleEvents.length - 1];
    const newStartTime = createNewStartTime(addHoursToDate(new Date(previousScheduleEvent.startTime), 1));
    const newMinDateTime = addHoursToDate(new Date(previousScheduleEvent.minDateTime), 1);
    const newScheduleEvent = {
      programTerms: undefined,
      startTime: new Date(newStartTime).toISOString(),
      description: null,
      minDateTime: newMinDateTime,
      errors: {},
    };
    setScheduleEvents([...scheduleEvents, newScheduleEvent]);
  };

  const removeScheduleEvent = (): void => {
    const lastIndex = scheduleEvents.length - 1;
    const validatedScheduleEvents = validateStartTimes(R.remove(lastIndex, 1, scheduleEvents));
    setScheduleEvents(validatedScheduleEvents);
  };

  const updateScheduleEvents: UpdateMultipleEntityFormHandler = (
    index: number,
    field: string,
    value: number | string | boolean | DropDownSelectorOption
  ): void => {
    let updatedScheduleEvents;
    switch (field) {
      case "startTime":
        updatedScheduleEvents = validateStartTimes(
          R.assocPath([index, field], new Date(value as string).toISOString(), scheduleEvents)
        );
        break;
      case "programTermsUnselected":
        updatedScheduleEvents = R.assocPath(
          [index, "errors", field],
          value === undefined ? true : false,
          scheduleEvents
        );
        break;
      case "programTerms":
        updatedScheduleEvents = R.assocPath([index, field], value, scheduleEvents).map((schedule) => {
          if (schedule.programTerms === undefined) {
            return {
              ...schedule,
              errors: {
                ...schedule.errors,
                programTermsUnselected: true,
              },
            };
          }
          return schedule;
        });
        break;
      default:
        updatedScheduleEvents = R.assocPath([index, field], value, scheduleEvents);
        break;
    }

    setScheduleEvents(updatedScheduleEvents);
    setCheckValidation(true);
  };

  const touchAllProgramTerms = () => {
    const touchedPTscheduleEvents = scheduleEvents.map((schedule) => {
      if (schedule.programTerms === undefined) {
        return {
          ...schedule,
          errors: {
            ...schedule.errors,
            programTermsUnselected: true,
          },
        };
      }
      return schedule;
    });

    setScheduleEvents(touchedPTscheduleEvents);
  };

  const programTermsUnselectedError = scheduleEvents.some((event) => event.programTerms === undefined);

  const programTermsTsAndCsError = scheduleEvents.some((event) => event.errors.programTerms !== undefined);

  const descriptionError = scheduleEvents.some((event) => event.errors.description !== undefined);

  return {
    scheduleEvents: scheduleEvents as Array<ScheduleEventFormEntry>,
    updateScheduleEvents,
    addScheduleEvent,
    removeScheduleEvent,
    touchAllProgramTerms,
    programTermsUnselectedError,
    programTermsTsAndCsError,
    descriptionError,
  };
};
