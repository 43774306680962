import { useQuery } from "@tanstack/react-query";
import { fetchActiveProgramTerms } from "api/fetchActiveProgramTerms";
import { useUser } from "./useUser";
import { currentCompanyId } from "utils/authorizationUtils";

export const useActiveProgramTerms = () => {
  const { user } = useUser();
  const advertiserId = currentCompanyId(user);

  return useQuery(["activeProgramTerms", advertiserId], () => fetchActiveProgramTerms(advertiserId));
};
