import * as R from "ramda";
import { useIntl } from "hooks";
import { ScheduleEventFormEntry } from "localTypes";

export const useValidateScheduleEventDescription = () => {
  const intl = useIntl();
  const validateDescription = (scheduleEvent: ScheduleEventFormEntry) => {
    if (scheduleEvent.description === null) {
      return true;
    }

    return scheduleEvent.description.length <= 100;
  };

  const validateScheduleEventDescriptions = R.map(
    R.ifElse(
      validateDescription,
      R.assocPath(["errors", "description"], undefined),
      R.assocPath(
        ["errors", "description"],
        intl?.formatMessage({
          id: "relationships.scheduleEventDescriptionMaxLength",
          defaultMessage: "Description should not be more than 100 characters long.",
        })
      )
    )
  );

  return {
    validateScheduleEventDescriptions,
  };
};
