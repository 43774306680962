import { Modal, ModalProps } from "@cjdev-internal/visual-stack-x/components/Modal";

import "./styles.css";

export const SimpleModal = ({ ...props }: ModalProps) => {
  return (
    <Modal
      headerTitle={props.headerTitle}
      headerContent={props.headerContent}
      body={props.body}
      footer={props.footer}
      onBackgroundClick={props.onBackgroundClick}
      onEscapeKeyUp={props.onEscapeKeyUp}
    />
  );
};
