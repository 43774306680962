import { CJField, CJForm, useCJForm, TextInput, SelectInput } from "@cjdev-internal/visual-stack-x/CJForm";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { FormPageBlock, FormPageLayout } from "@cjdev-internal/visual-stack-x/FormPageLayout";
import { FormModal } from "@platform/cj-platform-navigation";
import messages from "./messages";
import { FormattedMessage } from "components/FormattedMessage";
import { useBrandedActiveProgramTerms } from "views/BrandedSignupLinks/hooks/useBrandedActiveProgramTerms";
import { DropDownSelectorOption } from "components/DropDownSelector";
import { exportSignupLinksToCSV, ExportSignupLinksToCsvProps } from "utils/downloadCsv";
import { Row } from "@cjdev-internal/visual-stack-x/Row";
import { Button } from "@cjdev-internal/visual-stack-x/Button";
import { validateCreatorIds } from "views/BrandedSignupLinks/utils";
import { useIntl } from "../../hooks";
import { Stack } from "@cjdev-internal/visual-stack-x/Stack";

export const BrandedSignupLinksForm = () => {
  const validateCreatorIdsInput = (str: string) => {
    const isValid = validateCreatorIds(str);
    return isValid || formatMessage(messages.invalidCreatorIds);
  };

  const { formatMessage } = useIntl();
  const config = useCJForm<ExportSignupLinksToCsvProps>({ mode: "onChange" });
  const activeProgramTermsOptions = useBrandedActiveProgramTerms();
  return (
    <FormPageLayout verticalPad width="wide" className={"branded-signup-links-layout"}>
      <FormPageBlock>
        <CJForm
          {...config}
          showRequiredIndicator
          heading={formatMessage(messages.createSignUpLinksHeader)}
          onSubmit={(data) => exportSignupLinksToCSV(data)}
        >
          <CJField label={formatMessage(messages.programTerms)} required name="programTerms">
            <SelectInput
              name="programTerms"
              options={activeProgramTermsOptions as DropDownSelectorOption[]}
              required={formatMessage(messages.programTermsRequired)}
              placeholder={
                <FormattedMessage id={"relationships.selectProgramTerms"} defaultMessage={"Select Program Terms"} />
              }
            />
          </CJField>
          <Stack gap="small">
            <Text weight="medium" size={12}>
              <FormattedMessage
                id={"relationships.ptApprovalSettings"}
                defaultMessage={"Program Terms Approval Settings"}
              />
            </Text>
            <Text size={12}>
              <FormattedMessage id={"relationships.statusAutoApproved"} defaultMessage={"Status: Auto Approved"} />
            </Text>
            <Text size={12} color="secondary">
              <FormattedMessage
                id={"relationships.autoApprovedLinkDisclaimer"}
                defaultMessage={
                  "Publishers who sign up with the custom link will be automatically accepted into the program terms selected. No application approval will be required."
                }
              />
            </Text>
          </Stack>
          <CJField
            name="creatorIds"
            required
            help={formatMessage(messages.creatorIdsHelpText)}
            label={formatMessage(messages.creatorIds)}
          >
            <TextInput
              name="creatorIds"
              required={formatMessage(messages.creatorIdsRequired)}
              validate={validateCreatorIdsInput}
            />
          </CJField>
          <Row justify="end" gap="medium">
            <Button type="primary" htmlType="submit">
              <FormattedMessage id={"relationships.downloadLinks"} defaultMessage={"Download Links"} />
            </Button>
          </Row>
        </CJForm>
      </FormPageBlock>
    </FormPageLayout>
  );
};

export const BrandedSignupLinksFormNewNav = () => {
  return (
    <FormModal
      isOpen
      onRequestClose={() => window.history.back()}
      title={<FormattedMessage id={"relationships.BrandedSingupLinks"} defaultMessage={"Branded Signup Links"} />}
    >
      <BrandedSignupLinksForm />
    </FormModal>
  );
};
