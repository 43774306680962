import { useContext, Context } from "react";
import { throwUninitializedError } from "./helpers/errorHelpers";

export type ProviderInitializedContext = {
  providerInitialized: boolean;
};

export const useProviderInitializedContext = <T extends ProviderInitializedContext>(providerContext: Context<T>): T => {
  const context = useContext(providerContext);
  if (context.providerInitialized) {
    return context;
  }
  throwUninitializedError();
  return {} as T; // compiler doesn't know this is unreachable
};
