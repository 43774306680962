import { useQuery } from "@tanstack/react-query";
import { authenticatedFetch } from "api/authenticatedFetch";
import { getMemberHost } from "config";

export type RolloverPeriodResponse = {
  programTermsRolloverPeriod: number;
};

export const useRolloverPeriod = (advertiserId: number) => {
  const { data } = useQuery(
    ["rollover-period", advertiserId],
    async () => {
      const response = await authenticatedFetch<RolloverPeriodResponse>(
        `https://${getMemberHost()}/affapi/advertiser/${advertiserId}/programterms/rollover-period`
      );
      return response.programTermsRolloverPeriod;
    },
    {
      enabled: !!advertiserId,
    }
  );

  return data;
};
