import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Button } from "@cjdev-internal/visual-stack-x/Button";
import { Stack } from "@cjdev-internal/visual-stack-x/Stack";
import { Row } from "@cjdev-internal/visual-stack-x/Row";
import { Suspense } from "components/Suspense";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { SimpleModal } from "components/SimpleModal";
import { HorizontalLine } from "components/HorizontalLine";
import { FormattedMessage } from "../../../components/FormattedMessage";
import { Maybe, OfferToProgramTermsResponse, ScheduleOfferResponse } from "types";
import { isEmpty } from "utils";
import { ProgramTermsReplacementRules } from "./ProgramTermsReplacementRules";
import CloseIcon from "mdi-react/CloseIcon";
import { useOfferProgramTerms, useOfferSelectedTargets, useShowReplacementRules } from "../hooks";
import { Heading } from "@cjdev-internal/visual-stack-x/Heading";

export type ConfirmationResultsModalContentProps = {
  navigateToReturnUrl: () => void;
  onClose: () => void;
  scheduleOfferApiResults?: Maybe<ScheduleOfferResponse>;
  totalNumberOfPublishers?: number;
  serverErrors?: Maybe<Error[]>;
};

export type ConfirmationResultsModalProps = {
  onClose: () => void;
};

const isOfferProgramTermsType = (
  offerResponse: Maybe<ScheduleOfferResponse>
): offerResponse is OfferToProgramTermsResponse => {
  return (offerResponse as OfferToProgramTermsResponse) !== undefined;
};

export const ConfirmationResultsModalContent = ({
  navigateToReturnUrl,
  onClose,
  scheduleOfferApiResults,
  totalNumberOfPublishers,
  serverErrors,
}: ConfirmationResultsModalContentProps) => {
  const { showReplacementRules, toggleReplacementRules } = useShowReplacementRules();
  const failedProgramTerms = scheduleOfferApiResults?.failedProgramTerms;
  const successfulPublishers = scheduleOfferApiResults?.succeededPublishers;
  const succeededProgramTerms =
    scheduleOfferApiResults &&
    isOfferProgramTermsType(scheduleOfferApiResults) &&
    scheduleOfferApiResults.succeededProgramTerms;
  const succeededPublishersCount: number =
    totalNumberOfPublishers && successfulPublishers ? successfulPublishers.length : 0;

  const successfulProgramTermsBasedOffer =
    isEmpty(serverErrors) && !isEmpty(succeededProgramTerms) && !totalNumberOfPublishers;

  const failedOffer = failedProgramTerms?.length;

  return (
    <>
      <Heading size={18}>
        <Box direction="row" justify="space-between">
          <FormattedMessage
            id="relationships.confirmationResultsModal.header"
            defaultMessage="Program Terms Schedule"
          />
          {!!failedOffer && <CloseIcon data-testid="cancel-link" onClick={onClose} />}
        </Box>
      </Heading>
      <Stack gap="medium">
        {!!totalNumberOfPublishers && isEmpty(serverErrors) && (
          <Row>
            <FormattedMessage
              id="relationships.confirmationResultsModal.numberOfUpdatedPublishers"
              defaultMessage="{succeededPublishersCount} of {totalNumberOfPublishers} publisher(s) have been set to have their program terms replaced."
              values={{
                succeededPublishersCount,
                totalNumberOfPublishers,
              }}
            />
          </Row>
        )}
        {successfulProgramTermsBasedOffer && (
          <Row>
            <Text>
              <FormattedMessage
                id="relationships.confirmationResultsModal.systemProcessedRequest"
                defaultMessage="The system has processed your request successfully."
              />
            </Text>
          </Row>
        )}
        {!isEmpty(serverErrors) && (
          <Stack gap="large">
            <Text>
              We could not offer the schedule due to the following errors. Please create a new schedule and try again.
            </Text>
            {serverErrors?.map((e) => {
              return <Text key={e.message}>{e.message}</Text>;
            })}
          </Stack>
        )}
        {!!failedOffer && (
          <>
            <Row>
              <Text color="critical">
                <FormattedMessage
                  id="relationships.confirmationResultsModal.theFollowingDidNotMeetRequirements"
                  defaultMessage="The following program terms did not meet the start date requirements and could not be replaced:"
                />
              </Text>
            </Row>
            <Row>
              <ul>
                {failedProgramTerms?.map((failedProgramTerm: { id: number; name: string; reason?: string }) => (
                  <li key={failedProgramTerm.id}>
                    <Text>{failedProgramTerm.name}</Text>
                  </li>
                ))}
              </ul>
            </Row>
            <Row>
              <Text>
                <FormattedMessage
                  id="relationships.confirmationResultsModal.toFixTheErrors"
                  defaultMessage={"To fix the errors:"}
                />
              </Text>
            </Row>
            <Row>
              <ol>
                <li>
                  <Text>
                    <FormattedMessage
                      id="relationships.confirmationResultsModal.reviewPTsRules"
                      defaultMessage={"Click {link} to review them below"}
                      values={{
                        link: (
                          <Text color="link" onClick={!showReplacementRules ? toggleReplacementRules : undefined}>
                            <FormattedMessage
                              id="relationships.confirmationResultsModal.programTermsRules"
                              defaultMessage="program terms rules"
                            />
                          </Text>
                        ),
                      }}
                    />
                  </Text>
                </li>
                <li>
                  <Text>
                    <FormattedMessage
                      id="relationships.confirmationResultsModal.createPTsScheduleAndApply"
                      defaultMessage={"Create a program terms schedule and apply changes."}
                    />
                  </Text>
                </li>
                <li>
                  <Text>
                    <FormattedMessage
                      id="relationships.confirmationResultsModal.sendNewPTsScheduleOffer"
                      defaultMessage={"Send new program terms schedule offer."}
                    />
                  </Text>
                </li>
              </ol>
            </Row>
            {isEmpty(serverErrors) && (
              <>
                <Box paddingBottom="medium" />
                <HorizontalLine type="solid" />
                {showReplacementRules ? (
                  <>
                    <Box paddingBottom="large" />
                    <Text color="link" onClick={toggleReplacementRules}>
                      <FormattedMessage
                        id="relationships.confirmationResultsModal.hideReplacementRules"
                        defaultMessage="Hide replacement rules"
                      />
                    </Text>
                    <Box paddingBottom="medium" />
                    <ProgramTermsReplacementRules />
                  </>
                ) : (
                  <>
                    <Box paddingBottom="large" />
                    <Text color="link" onClick={toggleReplacementRules}>
                      <FormattedMessage
                        id="relationships.confirmationResultsModal.showReplacementRules"
                        defaultMessage="Show replacement rules"
                      />
                    </Text>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Stack>
      <Box direction="row" justify="end" gap="medium-large">
        <Button type="primary" onClick={navigateToReturnUrl}>
          <FormattedMessage id="relationships.confirmationResultsModal.close" defaultMessage="Close" />
        </Button>
      </Box>
    </>
  );
};

export const ConfirmationResultsModal = ({ ...props }: ConfirmationResultsModalProps) => {
  const { scheduleOfferApiResults, serverErrors, navigateBackToMember } = useOfferProgramTerms();

  const { numberOfSelectedPublishers } = useOfferSelectedTargets();

  return (
    <SimpleModal
      onBackgroundClick={() => undefined}
      body={
        <Suspense padding="xl" gap="xl">
          <ConfirmationResultsModalContent
            onClose={props.onClose}
            navigateToReturnUrl={navigateBackToMember}
            scheduleOfferApiResults={scheduleOfferApiResults}
            totalNumberOfPublishers={numberOfSelectedPublishers}
            serverErrors={serverErrors}
          />
        </Suspense>
      }
    />
  );
};
