import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Button } from "@cjdev-internal/visual-stack-x/Button";
import { HoverPopover } from "@cjdev-internal/visual-stack-x/Popover";
import { ChoiceInput, Field } from "@cjdev-internal/visual-stack-x/legacy/Form";
import { Grid } from "@cjdev-internal/visual-stack-x/Grid";
import { Heading } from "@cjdev-internal/visual-stack-x/Heading";
import { Row } from "@cjdev-internal/visual-stack-x/Row";
import { Stack } from "@cjdev-internal/visual-stack-x/Stack";
import { Text } from "@cjdev-internal/visual-stack-x/Text";

import CheckCircleOutlineIcon from "mdi-react/CheckCircleOutlineIcon";
import InfoCircleOutlineIcon from "mdi-react/InfoCircleOutlineIcon";

import { FormModalWithOptions } from "@platform/cj-platform-navigation";

import "./stylesheets/OfferProgramTerms.css";

import { useIntl, useNav, useRelationshipStatuses, useModal, useUser } from "hooks";

import { DropDownSelector, DropDownSelectorOption } from "components/DropDownSelector";
import { HorizontalLine } from "components/HorizontalLine";
import { PageLayout } from "components/PageLayout";

import { FormattedMessage } from "components/FormattedMessage";

import { FormErrorsAlert } from "./components/FormErrorsAlert";
import { ScheduleEventMultipleForm } from "./components/ScheduleEventForm";
import { ScheduleNameField } from "./components/ScheduleNameField";
import { TimeZoneErrorsAlert } from "./components/TimeZoneErrorsAlert";

import {
  useOfferOptions,
  useOfferProgramTerms,
  useScheduleEventsForm,
  useScheduleName,
  useOfferSelectedTargets,
  useTimeZone,
} from "views/OfferProgramTerms/hooks";

import { timeZoneOptions } from "utils/timeZoneUtils";
import { currentCompanyId } from "utils/authorizationUtils";

import { useAlert } from "@cjdev-internal/visual-stack-x/Alert";

export const OfferProgramTerms = () => {
  const TEXT_SIZE = 12;
  const { use2022Nav } = useNav();
  const { saveOnClickHandler, navigateBackToMember } = useOfferProgramTerms();

  const { formatMessage } = useIntl();

  const { user } = useUser();
  const advertiserId = currentCompanyId(user);

  const { replaceProgramTerms, numberOfSelectedProgramTerms, offerToPublishers, numberOfSelectedPublishers } =
    useOfferSelectedTargets();

  const { timeZone, setTimeZone } = useTimeZone();

  const { allowEarlyAccept, setAllowEarlyAccept } = useOfferOptions();

  const { scheduleName, setScheduleName, touchScheduleName, isScheduleNameTouched, scheduleNameError } =
    useScheduleName();

  const { scheduleEvents, addScheduleEvent, removeScheduleEvent } = useScheduleEventsForm();

  const { uniqueProgramTermsName, uniqueProgramTerms } = useRelationshipStatuses(advertiserId, {
    skip: replaceProgramTerms,
  });

  const offerTarget = replaceProgramTerms
    ? formatMessage({
        id: "relationships.OfferProgramTerms.programTerms",
        defaultMessage: "program terms",
      })
    : formatMessage({
        id: "relationships.publishers",
        defaultMessage: "publishers",
      });

  const { mount, openSchedulingRulesModal } = useModal();

  const [alertMount, showAlert] = useAlert();

  return (
    <>
      {mount}
      {alertMount}
      <PageLayout>
        <FormErrorsAlert showAlert={showAlert} />
        <TimeZoneErrorsAlert showAlert={showAlert} />
        <Stack gap="xl">
          <Stack>
            <Heading size={18}>
              <FormattedMessage id="relationships.scheduleProgramTerms" defaultMessage="Schedule Program Terms" />
            </Heading>
            <Text color="secondary">
              <FormattedMessage
                id="relationships.programTermsWillBeSentTo{target}"
                defaultMessage="Program terms will be sent to the selected {target}."
                values={{ target: offerTarget }}
              />
            </Text>
          </Stack>

          <Stack gap="small">
            <Row gap="medium" align="center">
              <CheckCircleOutlineIcon style={{ color: "var(--color-success)" }} size={18} />
              {replaceProgramTerms ? (
                <FormattedMessage
                  id="relationships.programTermsSelected"
                  defaultMessage="{count} program terms selected"
                  values={{ count: numberOfSelectedProgramTerms }}
                />
              ) : (
                <FormattedMessage
                  id="relationships.publishersSelected"
                  defaultMessage="{count} publishers selected"
                  values={{ count: numberOfSelectedPublishers }}
                />
              )}
            </Row>
            {offerToPublishers && uniqueProgramTerms.length > 0 && (
              <Row style={{ marginLeft: "28px" }} className={"active-program-terms-row"}>
                <FormattedMessage
                  id="relationships.activeProgramTermsOnPublishers"
                  defaultMessage="<b>Active program terms:</b>&nbsp;{activeProgramTermsMessage}"
                  values={{
                    activeProgramTermsMessage:
                      uniqueProgramTerms.length > 1
                        ? formatMessage({
                            id: "relationships.activeProgramTermsOnPublishers.multiple",
                            defaultMessage: "Selected publishers are on multiple active program terms.",
                          })
                        : uniqueProgramTermsName,
                    b: (chunks) => <Text weight="bold">{chunks}</Text>,
                  }}
                />
              </Row>
            )}
          </Stack>

          <ScheduleNameField
            value={scheduleName}
            onChange={setScheduleName}
            onBlur={touchScheduleName}
            error={isScheduleNameTouched && scheduleNameError}
          />

          <Grid columns="1fr 1fr">
            <DropDownSelector
              inputId="time-zone"
              options={timeZoneOptions}
              label={<FormattedMessage id="relationships.timeZone" defaultMessage="Time Zone" />}
              value={timeZone as DropDownSelectorOption}
              onUpdate={setTimeZone}
            />
          </Grid>

          <Field
            className={"select-start-date-type"}
            label={<FormattedMessage id="relationships.chooseAScheduleType" defaultMessage="Choose a schedule type" />}
          >
            <Stack gap={"small"}>
              <ChoiceInput
                label={
                  <Text size={TEXT_SIZE} weight={"medium"}>
                    <FormattedMessage id="relationships.flexibleStartDate" defaultMessage={"Flexible Start Date"} />
                  </Text>
                }
                onChange={() => setAllowEarlyAccept(true)}
                checked={allowEarlyAccept}
              />
              <Box className={"schedule-type-description"}>
                <Text size={TEXT_SIZE}>
                  <FormattedMessage
                    id="relationships.flexibleStartDateDescription"
                    defaultMessage={
                      "Allow publishers to accept and start the schedule at any time before the set start date and time."
                    }
                  />
                </Text>
              </Box>
              <Box direction="row" className={"schedule-type-description"}>
                <Text color="secondary" size={TEXT_SIZE}>
                  <InfoCircleOutlineIcon style={{ verticalAlign: "text-bottom" }} size={18} />
                  &nbsp;
                  <FormattedMessage
                    id="relationships.requiredForSchedulesWithSpecialTsAndCsChanges"
                    defaultMessage={"This option is required for schedules with Special T's and C's changes."}
                  />
                </Text>
              </Box>
              <Box className={"schedule-type-description"}>
                <HoverPopover
                  placement={"bottom"}
                  content={
                    <Stack gap="medium">
                      <Row>
                        <FormattedMessage
                          id="relationships.flexibleStartDateExample"
                          defaultMessage={
                            "Example: The publisher can accept before the start date and time of the first program terms in the schedule. The publisher will rollover to the first program terms on the set start date and time if they do not accept early."
                          }
                        />
                      </Row>

                      <Row>
                        <FormattedMessage
                          id="relationships.flexibleStartDateRequiredForSpecialTsAndCsChanges"
                          defaultMessage={
                            "If the schedule contains Special Terms and Conditions changes, a flexible start date is required. If the publisher does not accept before the set start date and time of the first program terms, they will be expired from your program."
                          }
                        />
                      </Row>
                    </Stack>
                  }
                >
                  <Text className="example" size={TEXT_SIZE}>
                    <FormattedMessage id="relationships.example" defaultMessage={"Example"} />
                  </Text>
                </HoverPopover>
              </Box>
              <ChoiceInput
                label={
                  <Text size={TEXT_SIZE} weight={"medium"}>
                    <FormattedMessage id="relationships.fixedStartDate" defaultMessage={"Fixed Start Date"} />
                  </Text>
                }
                onChange={() => setAllowEarlyAccept(false)}
                checked={!allowEarlyAccept}
              />
              <Box className={"schedule-type-description"}>
                <Text size={TEXT_SIZE}>
                  <FormattedMessage
                    id="relationships.publisherCannotAcceptBefore"
                    defaultMessage={"Publishers can accept the schedule and start only on the set fixed date and time."}
                  />
                </Text>
              </Box>
              <Box className={"schedule-type-description"} inline>
                <HoverPopover
                  className="example"
                  placement={"bottom"}
                  content={
                    <Stack gap="large">
                      <Row>
                        <FormattedMessage
                          id="relationships.fixedStartDateExample"
                          defaultMessage={
                            "Example: The publisher cannot accept before the start date and time of the first program terms in the schedule."
                          }
                        />
                      </Row>
                      <Row>
                        <FormattedMessage
                          id="relationships.fixedStartDateWillRolloverUnlessPublisherDeclines"
                          defaultMessage={
                            "Unless the publisher declines the offer, they will rollover to the first program terms on the set start date and time."
                          }
                        />
                      </Row>
                    </Stack>
                  }
                >
                  <Text className="example" size={TEXT_SIZE}>
                    <FormattedMessage id="relationships.example" defaultMessage={"Example"} />
                  </Text>
                </HoverPopover>
              </Box>
            </Stack>
          </Field>

          <HorizontalLine type="solid" />

          <Stack gap="xs">
            <Heading size={13}>
              <FormattedMessage id="relationships.scheduling" defaultMessage="Scheduling" />
            </Heading>
            <Text color="secondary" size={TEXT_SIZE}>
              <FormattedMessage
                id="relationships.schedule1orMoreProgramTerms"
                defaultMessage="Schedule 1 or more program terms in the publisher offer. The last program terms will be set as: Ongoing."
              />
            </Text>
          </Stack>

          <Row gap="small" align="center">
            <InfoCircleOutlineIcon style={{ color: "var(--color-critical)" }} size={18} />

            <Text size={TEXT_SIZE}>
              <FormattedMessage
                id="relationships.reviewBestPracticesAndRules"
                defaultMessage="Review {link} to avoid scheduling errors. The program terms rules only apply to the first terms in the schedule."
                values={{
                  link: (
                    <Text color="link" size={TEXT_SIZE} onClick={openSchedulingRulesModal}>
                      <FormattedMessage
                        id="relationships.reviewBestPracticesAndRulesLink"
                        defaultMessage="best practices and scheduling rules"
                      />
                    </Text>
                  ),
                }}
              />
            </Text>

            {/* <SchedulingRulesModal /> */}
          </Row>

          <HorizontalLine type="dashed" />

          <ScheduleEventMultipleForm />

          <Text color="secondary" size={TEXT_SIZE}>
            <FormattedMessage
              id="relationships.lastProgramTermsOngoing"
              defaultMessage="The schedule will end with the last program terms in the schedule continuing as ONGOING."
            />
          </Text>

          <Row gap="medium-large">
            <Button type="secondary" onClick={() => addScheduleEvent()}>
              <FormattedMessage id="relationships.addAnotherTerm" defaultMessage="Add another" />
            </Button>
            {scheduleEvents.length > 1 && (
              <Button type="secondary" onClick={() => removeScheduleEvent()}>
                <FormattedMessage id="relationships.remove" defaultMessage="Remove last" />
              </Button>
            )}
          </Row>
        </Stack>
        {!use2022Nav && (
          <Box direction="row" justify="end" gap="medium-large">
            <Button type="secondary" onClick={navigateBackToMember}>
              <FormattedMessage id="relationships.cancel" defaultMessage="Cancel" />
            </Button>
            <Button type={"primary"} onClick={saveOnClickHandler}>
              <FormattedMessage id="relationships.save" defaultMessage="Save" />
            </Button>
          </Box>
        )}
      </PageLayout>
    </>
  );
};

export const OfferProgramTermsViewNewNav = () => {
  const { saveOnClickHandler, navigateBackToMember } = useOfferProgramTerms();

  return (
    <>
      <FormModalWithOptions
        isOpen
        onRequestClose={navigateBackToMember}
        onSubmit={saveOnClickHandler}
        submitText={<FormattedMessage id="relationships.save" defaultMessage="Save" />}
        title={<FormattedMessage id="relationships.OfferProgramTerms" defaultMessage="Offer Program Terms" />}
      >
        <OfferProgramTerms />
      </FormModalWithOptions>
    </>
  );
};
