import { useQuery } from "@tanstack/react-query";
import { fetchFeatures } from "../api/fetchFeatures";

export const useFeatures = (companyId: number) => {
  const { data: features } = useQuery(["features"], async () => await fetchFeatures(companyId), {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
  return {
    hasFeature: (feature: string) => features && features.indexOf(feature) >= 0,
  };
};
