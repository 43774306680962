import { QueryClient, QueryClientProvider as ReactQueryClientProvider } from "@tanstack/react-query";
import { useMemo } from "react";

export const QueryClientProvider = ({ ...props }) => {
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            suspense: true,
            retry: 0,
          },
        },
      }),
    []
  );

  return <ReactQueryClientProvider client={queryClient} {...props} />;
};
