import { css } from "@emotion/css";

export type HorizontalLineProps = {
  type: "solid" | "dashed";
};

export const HorizontalLine = ({ type }: HorizontalLineProps) => (
  <hr
    className={css`
      width: 100%;
      height: 0;
      margin-bottom: 0;
      margin-top: 0;
      border: 0;
      border-top: 1px solid #e1e1e1;
      border-style: ${type};
    `}
  />
);
