import { FormattedMessage } from "../FormattedMessage";
import { Field } from "@cjdev-internal/visual-stack-x/Field";
import { Select } from "@cjdev-internal/visual-stack-x/Select";
import { ErrorText } from "../ErrorText";

export type DropDownSelectorOption = {
  label: string;
  value: string | number;
  specialTermsId?: number;
};

export type DropDownSelectorProps = {
  inputId: string;
  options: Array<DropDownSelectorOption>;
  value: DropDownSelectorOption;
  onUpdate: (e: DropDownSelectorOption) => void;
  label?: React.ReactNode;
  placeholder?: React.ReactNode;
  programTermsError?: string;
  requiredError?: boolean;
  touchDropDownSelector?: React.FocusEventHandler<HTMLInputElement>;
};

export const DropDownSelector = ({
  inputId,
  options,
  value,
  onUpdate,
  label,
  placeholder,
  programTermsError,
  requiredError,
  touchDropDownSelector,
}: DropDownSelectorProps) => {
  return (
    <Field
      label={label}
      required
      error={
        <>
          {programTermsError && <ErrorText>{programTermsError}</ErrorText>}
          {requiredError && (
            <ErrorText>
              <FormattedMessage
                id="relationships.programTermsIsRequiredErrorMessage"
                defaultMessage={"Program terms is required."}
              />
            </ErrorText>
          )}
        </>
      }
      labelFor={inputId}
    >
      <Select
        inputId={inputId}
        options={options}
        value={value}
        error={!!programTermsError || !!requiredError}
        placeholder={placeholder}
        onChange={(newValue: unknown) => onUpdate(newValue as DropDownSelectorOption)}
        onBlur={touchDropDownSelector}
      />
    </Field>
  );
};
