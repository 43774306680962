import { getMemberHost } from "config";
import { PostScheduleOfferPayload, ScheduleOfferResponse } from "types";
import { authenticatedFetch } from "./authenticatedFetch";

export const postScheduleOffer = async (advertiserId: number, payload: PostScheduleOfferPayload) =>
  await authenticatedFetch<ScheduleOfferResponse>(
    `https://${getMemberHost()}/affapi/advertisers/${advertiserId}/schedule-offers`,
    {
      method: "POST",
      body: JSON.stringify(payload),
    }
  );
