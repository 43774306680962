import { FormattedMessage } from "../../../components/FormattedMessage";
import { useAlert } from "../hooks";
import { AlertProps } from "@cjdev-internal/visual-stack-x/Alert";
import { useEffect } from "react";

type FormErrorsAlertProps = {
  showAlert: (props: AlertProps | null) => void;
};

export const FormErrorsAlert = ({ showAlert }: FormErrorsAlertProps) => {
  const { showFormErrorsAlert, setShowFormErrorsAlert } = useAlert();

  useEffect(() => {
    if (showFormErrorsAlert) {
      showAlert({
        closeType: "icon",
        type: "warning",
        onClose: () => {
          setShowFormErrorsAlert(false);
          showAlert(null);
        },
        children: (
          <FormattedMessage
            id="relationships.formErrorsAlert"
            defaultMessage="Please fix your errors in red below and then click save"
          />
        ),
      });
    }
  }, [showFormErrorsAlert, showAlert, setShowFormErrorsAlert]);
  return null;
};
