import { fetchRelationshipStatuses } from "api/fetchRelationshipStatuses";
import { useRelationshipStatusesContextRetriever } from "context/RelationshipStatusesContext";
import { useEffect } from "react";
import { useActiveProgramTermsOptions } from "views/OfferProgramTerms/hooks";

export const useRelationshipStatuses = (advertiserId: number, options?: { skip: boolean }) => {
  const { relationshipStatuses, setRelationshipStatuses } = useRelationshipStatusesContextRetriever();
  const { activeProgramTermsOptions } = useActiveProgramTermsOptions(advertiserId);

  useEffect(() => {
    const callApi = async () => {
      const retrievedRelationshipStatuses = await fetchRelationshipStatuses(advertiserId);
      setRelationshipStatuses(retrievedRelationshipStatuses || []);
    };

    if (!options?.skip) {
      callApi();
    } else {
      setRelationshipStatuses([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advertiserId]);

  const programTermsIds = relationshipStatuses?.map((status) => status.programTermsId) ?? [];
  const activeProgramTermsIds = programTermsIds.filter((id) => id !== -1);
  const distinctTermsIds = new Set(activeProgramTermsIds);
  const uniqueProgramTerms = Array.from(distinctTermsIds);

  const findProgramTermsName = (programTermsId: number) =>
    activeProgramTermsOptions?.find((option) => option.value === programTermsId)?.label ?? "";

  const uniqueProgramTermsName = uniqueProgramTerms.length === 1 ? findProgramTermsName(uniqueProgramTerms[0]) : "";

  return {
    relationshipStatuses,
    uniqueProgramTerms,
    uniqueProgramTermsName,
  };
};
