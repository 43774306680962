import { DropDownSelectorOption } from "components/DropDownSelector";
import { useActiveProgramTerms } from "hooks/useActiveProgramTerms";
import { ProgramTerms } from "types";
import * as R from "ramda";
import { useIntl } from "hooks/useIntl";
import { getPublisherIdsFromUrl } from "utils/urlUtils";
import { useFeatures } from "hooks/useFeatures";

type ActiveProgramTermsOptions = {
  activeProgramTermsOptions: DropDownSelectorOption[];
  specialTermsIdLookup: (programTermsId: number) => number;
};

export const useActiveProgramTermsOptions = (advertiserId: number, index = 0): ActiveProgramTermsOptions => {
  const { data: activeProgramTermsFromApi } = useActiveProgramTerms();
  const { hasFeature } = useFeatures(advertiserId);
  const { formatMessage } = useIntl();

  if (!activeProgramTermsFromApi) {
    return {} as ActiveProgramTermsOptions;
  }

  const activeProgramTermsOptions: DropDownSelectorOption[] = activeProgramTermsFromApi
    .map(
      (programTerms: ProgramTerms): DropDownSelectorOption => ({
        label: programTerms.name,
        value: programTerms.id,
      })
    )
    .sort((a: DropDownSelectorOption, b: DropDownSelectorOption): number => {
      if (a.label === b.label) {
        return 0;
      }
      return a.label > b.label ? 1 : -1;
    });

  const publisherIds: number[] = getPublisherIdsFromUrl();
  const canRevert = index > 0 && hasFeature("PT_SCHEDULING_REVERT_SUPPORT") && publisherIds.length > 0;

  if (canRevert) {
    const label = formatMessage({
      id: "relationships.rollbackToActive",
      defaultMessage: "Rollback to active terms",
    });
    activeProgramTermsOptions.unshift({ label, value: -1 });
  }

  const specialTermsLookUpMap: Map<number, number> = R.reduce(
    (acc: Map<number, number>, programTerms: ProgramTerms) => {
      acc.set(programTerms.id, programTerms.specialTermsId);
      return acc;
    },
    new Map()
  )(activeProgramTermsFromApi);

  const specialTermsIdLookup = (programTermsId: number): number => specialTermsLookUpMap.get(programTermsId) || 0;

  return {
    activeProgramTermsOptions,
    specialTermsIdLookup,
  };
};
