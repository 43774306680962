import { startTransition } from "react";
import { useShowReplacementRulesContextRetriever } from "../context/ShowReplacementRulesContext";

export const useShowReplacementRules = () => {
  const { showReplacementRules, setShowReplacementRules } = useShowReplacementRulesContextRetriever();

  const toggleReplacementRules = () => {
    startTransition(() => {
      setShowReplacementRules(!showReplacementRules);
    });
  };

  return {
    showReplacementRules,
    toggleReplacementRules,
  };
};
