import { useIntl } from "hooks";
import { FormattedMessage } from "components/FormattedMessage";
import { InputField, InputFieldProps } from "@cjdev-internal/visual-stack-x/InputField";

export type ScheduleNameFieldProps = InputFieldProps<string>;

export const ScheduleNameField = (props: ScheduleNameFieldProps) => {
  const { formatMessage } = useIntl();
  return (
    <InputField
      {...props}
      id="schedule-name"
      required
      label={<FormattedMessage id="relationships.scheduleNameLabel" defaultMessage={"Schedule Name"} />}
      placeholder={formatMessage({
        id: "relationships.scheduleNamePlaceholder",
        defaultMessage: "Enter Name",
      })}
    />
  );
};
