import { createContext } from "react";
import { ProviderProps } from "../localTypes/ProviderTypes";

export type NavContextType = {
  use2022Nav: boolean;
  providerInitialized: boolean;
};
export const PlatformNavContext = createContext<NavContextType>({
  use2022Nav: false,
  providerInitialized: false,
});

export type PlatformNavProviderProps = ProviderProps & {
  use2022Nav: boolean;
};

export const PlatformNavProvider = ({ children, use2022Nav }: PlatformNavProviderProps) => {
  return (
    <PlatformNavContext.Provider
      value={{
        use2022Nav,
        providerInitialized: true,
      }}
    >
      {children}
    </PlatformNavContext.Provider>
  );
};
