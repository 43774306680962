import { createContext } from "react";
import { ProviderProps } from "localTypes/ProviderTypes";
import { useState } from "react";

export type AlertContextType = {
  startTimesErrors: boolean;
  setStartTimeErrors: React.Dispatch<React.SetStateAction<boolean>>;
  showFormErrorsAlert: boolean;
  setShowFormErrorsAlert: React.Dispatch<React.SetStateAction<boolean>>;
  showTimeZoneAlert: boolean;
  setShowTimeZoneAlert: React.Dispatch<React.SetStateAction<boolean>>;
  providerInitialized: boolean;
};

export const AlertContext = createContext<AlertContextType>({
  startTimesErrors: false,
  setStartTimeErrors: () => {},
  showFormErrorsAlert: false,
  setShowFormErrorsAlert: () => {},
  showTimeZoneAlert: false,
  setShowTimeZoneAlert: () => {},
  providerInitialized: false,
});

export const AlertProvider = ({ children }: ProviderProps) => {
  const [startTimesErrors, setStartTimeErrors] = useState<boolean>(false);
  const [showFormErrorsAlert, setShowFormErrorsAlert] = useState<boolean>(false);
  const [showTimeZoneAlert, setShowTimeZoneAlert] = useState<boolean>(false);

  return (
    <AlertContext.Provider
      value={{
        startTimesErrors,
        setStartTimeErrors,
        showFormErrorsAlert,
        setShowFormErrorsAlert,
        showTimeZoneAlert,
        setShowTimeZoneAlert,
        providerInitialized: true,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};
