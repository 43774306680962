import React from "react";
import { TopNavLayout } from "@cjdev-internal/visual-stack-x/TopNavLayout";
import { navigateToReturnUrl } from "utils/urlUtils";

type LayoutProps = {
  children: React.ReactNode;
  title: string;
  onCancel?: () => void | undefined;
};

// TODO: rename App Layout to LegacyAppLayout
export const AppLayout = ({ children, title, onCancel }: LayoutProps) => (
  <div>
    <TopNavLayout
      title={title}
      actions={{
        cancel: {
          content: "X",
          onClick: () => {
            if (onCancel) {
              onCancel();
            } else {
              navigateToReturnUrl();
            }
          },
        },
      }}
    >
      {children}
    </TopNavLayout>
  </div>
);
