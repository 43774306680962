import { Navigate, Routes, Route, BrowserRouter } from "react-router-dom";
import { OAuthCallback } from "components/OAuthCallback";
import { OfferProgramTerms, OfferProgramTermsViewNewNav } from "views/OfferProgramTerms";
import { AppLayout } from "components/AppLayout";
import { PlatformApp } from "@platform/cj-platform-navigation";
import translations from "translations";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { UserContextType, UserProvider } from "context/UserContext";
import { LegacyViewProvider } from "context/LegacyViewProvider";
import { OfferProgramTermsViewProvider } from "views/OfferProgramTerms/context/OfferProgramTermsViewProvider";
import { Authorization } from "components/Authorization";
import {
  isAdvertiserUser,
  currentCompanyMatchesQueryParam,
  allOf,
  brandedSignupAuthorizer,
  isSuperUserOrOperator,
} from "components/Authorization/authorizers";
import { isProduction } from "../config";
import { IntlProvider, RelationshipStatusesProvider } from "context";
import messages from "./messages";
import { ErrorPage } from "@cjdev-internal/visual-stack-x/components/ErrorPage";
import { BrandedSignupLinksForm, BrandedSignupLinksFormNewNav } from "views/BrandedSignupLinks";

export const RoutesList = () => (
  <Routes>
    <Route
      path="/offer-program-terms"
      element={
        <LegacyViewProvider>
          <Authorization authorizer={allOf([isAdvertiserUser, isSuperUserOrOperator])}>
            <RelationshipStatusesProvider>
              <OfferProgramTermsViewProvider>
                <AppLayout title="Offer Program Terms">
                  <OfferProgramTerms />
                </AppLayout>
              </OfferProgramTermsViewProvider>
            </RelationshipStatusesProvider>
          </Authorization>
        </LegacyViewProvider>
      }
    />
    <Route
      path="/branded-signup-links"
      element={
        <LegacyViewProvider>
          <Authorization authorizer={brandedSignupAuthorizer}>
            <AppLayout title="Branded Signup Links" onCancel={() => window.history.back()}>
              <IntlProvider>
                <BrandedSignupLinksForm />
              </IntlProvider>
            </AppLayout>
          </Authorization>
        </LegacyViewProvider>
      }
    />
    <Route path="/oauth-callback" element={<OAuthCallback />} />
    <Route path="/" element={<Navigate to="/offer-program-terms" />} />
  </Routes>
);

export const App = () => {
  return (
    <BrowserRouter>
      <RoutesList />
    </BrowserRouter>
  );
};

export const AppWithNav = () => {
  const routes = [
    {
      path: "offer-program-terms",
      titleMessage: { ...messages.offerProgamTerms },
      layout: {
        filters: false,
        sidenav: false,
        dimensions: false,
      },
      Content: (user: UserContextType) => {
        return (
          <UserProvider user={user.user}>
            <Authorization
              authorizer={allOf([isAdvertiserUser, isSuperUserOrOperator, currentCompanyMatchesQueryParam])}
            >
              <RelationshipStatusesProvider>
                <OfferProgramTermsViewProvider>
                  {false && <ReactQueryDevtools initialIsOpen={false} />}
                  <OfferProgramTermsViewNewNav />
                </OfferProgramTermsViewProvider>
              </RelationshipStatusesProvider>
            </Authorization>
          </UserProvider>
        );
      },
    },
    {
      path: "branded-signup-links",
      titleMessage: { ...messages.brandedSignupLinks },
      layout: {
        filters: false,
        sidenav: false,
        dimensions: false,
      },
      Content: (user: UserContextType) => {
        return (
          <UserProvider user={user.user}>
            <Authorization authorizer={brandedSignupAuthorizer}>
              <BrandedSignupLinksFormNewNav />
            </Authorization>
          </UserProvider>
        );
      },
    },
    {
      path: "*",
      titleMessage: { ...messages.pageNotFound },
      layout: {
        filters: false,
        sidenav: false,
        dimensions: false,
      },
      Content: () => <ErrorPage errorCode={404} />,
    },
  ];
  // the bare minimum, other domains will be added if/when we ever incorporate
  // the full nav into this area
  const platformDomains = isProduction()
    ? undefined
    : {
        members: "lab106",
      };
  return <PlatformApp platformDomains={platformDomains} routes={routes} translations={translations} />;
};
