import { ProviderProps } from "localTypes/ProviderTypes";
import { AlertProvider } from "./AlertContext";
import { OfferOptionsProvider } from "./OfferOptionsContext";
import { OfferProgramTermsProvider } from "./OfferProgramTermsContext";
import { ScheduleEventsProvider } from "./ScheduleEventsContext";
import { ScheduleNameProvider } from "./ScheduleNameContext";
import { ShowReplacementRulesProvider } from "./ShowReplacementRulesContext";
import { TimeZoneProvider } from "./TimeZoneContext";

export const OfferProgramTermsViewProvider = ({ children }: ProviderProps) => {
  return (
    <TimeZoneProvider>
      <ScheduleEventsProvider>
        <OfferOptionsProvider>
          <ScheduleNameProvider>
            <AlertProvider>
              <OfferProgramTermsProvider>
                <ShowReplacementRulesProvider>{children}</ShowReplacementRulesProvider>
              </OfferProgramTermsProvider>
            </AlertProvider>
          </ScheduleNameProvider>
        </OfferOptionsProvider>
      </ScheduleEventsProvider>
    </TimeZoneProvider>
  );
};
