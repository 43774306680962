import { getProgramTermsIdsToReplaceFromUrl, getPublisherIdsFromUrl } from "utils/urlUtils";

export const useSelectedTargets = () => {
  const selectedPublishers = getPublisherIdsFromUrl();
  const selectedProgramTerms = getProgramTermsIdsToReplaceFromUrl();
  const offerToPublishers = selectedPublishers.length > 0;
  const replaceProgramTerms = selectedProgramTerms.length > 0;

  if (offerToPublishers && replaceProgramTerms)
    throw Error("Cannot offer to publishers and replace program terms at the same time");
  if (!(offerToPublishers || replaceProgramTerms)) throw Error("One of publisherIds or termsToReplace is required");

  return {
    selectedPublishers,
    selectedProgramTerms,
    offerToPublishers,
    replaceProgramTerms,
  };
};
