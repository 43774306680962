import { ExecutionResult } from "graphql";
import { isEmpty } from "utils";
import { getBearerToken } from "utils/platformAuth";
import { redirectToLogin } from "utils/urlUtils";

export const authenticatedFetch = async <T>(url: string, options?: RequestInit) => {
  const response = await fetch(url, {
    ...options,
    headers: {
      Authorization: `Bearer ${await getBearerToken()}`,
      ...options?.headers,
    },
  });

  if (response.status === 401) {
    redirectToLogin();
    throw new Error(await response.text());
  }

  if (!response.ok) {
    throw new Error(await response.text());
  }

  const json = (await response.json()) as T;

  const graphQLResult = json as ExecutionResult<unknown>;

  if (!isEmpty(graphQLResult.errors)) {
    const error = graphQLResult.errors![0];
    if (error.extensions.code === "UNAUTHENTICATED") {
      redirectToLogin();
      throw new Error(error.message);
    }
  }

  return json;
};
