import { defineMessages } from "react-intl";

export default defineMessages({
  offerProgamTerms: {
    id: "relationships.OfferProgramTerms",
    defaultMessage: "Offer Program Terms",
  },
  brandedSignupLinks: {
    id: "relationships.BrandedSignupLinks",
    defaultMessage: "Branded Signup Links",
  },
  pageNotFound: {
    id: "relationships.pageNotFound",
    defaultMessage: "Page Not Found",
  },
});
