import { DropDownSelectorOption } from "components/DropDownSelector";
import { getAccountsApiHost } from "config";
import { SignupLinksResponse } from "types";
import { authenticatedFetch } from "./authenticatedFetch";

export const fetchGenerateSignUpLinks = async (
  programTerms: DropDownSelectorOption | undefined,
  publisherIds: string
) => {
  const programTermsId = programTerms?.value;
  return authenticatedFetch<Array<SignupLinksResponse>>(
    `https://${getAccountsApiHost()}/generate-influencer-signup-links?creatorIds=${publisherIds}&programTermsId=${programTermsId}`
  );
};
