import { DropDownSelectorOption } from "components/DropDownSelector";
import { getAllTimezones, Timezone } from "countries-and-timezones";
export type TimeZoneOption = DropDownSelectorOption & {
  value: Timezone;
};
type TimezoneKey = keyof typeof allTimeZones;

export const determineTimeZone = () => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timeZoneOptions.find((option) => option.value === tz) as TimeZoneOption;
};

const allTimeZones = getAllTimezones();

export const timeZoneOptions: Array<DropDownSelectorOption> = Object.keys(allTimeZones).map((name) => {
  const tz = allTimeZones[name as TimezoneKey];
  return {
    value: name,
    label: `${tz.name} (${tz.utcOffsetStr})`,
  };
});
