import { DropDownSelectorOption } from "components/DropDownSelector";
import { useActiveProgramTerms } from "hooks";
import { ProgramTerms } from "types";

export const useBrandedActiveProgramTerms = (): DropDownSelectorOption[] => {
  const { data: activeProgramTerms } = useActiveProgramTerms();
  const activeProgramTermsOptions = (activeProgramTerms || [])
    .filter((programTerms) => !programTerms.isContent)
    .map(
      (programTerms: ProgramTerms): DropDownSelectorOption => ({
        label: programTerms.name,
        value: programTerms.id,
      })
    )
    .sort((a: DropDownSelectorOption, b: DropDownSelectorOption): number => {
      if (a.label === b.label) {
        return 0;
      }
      return a.label > b.label ? 1 : -1;
    });

  return activeProgramTermsOptions;
};
