import { useSelectedTargets } from "hooks";

export const useOfferSelectedTargets = () => {
  const { selectedPublishers, selectedProgramTerms, offerToPublishers, replaceProgramTerms } = useSelectedTargets();

  const numberOfSelectedPublishers = selectedPublishers?.length ?? 0;
  const numberOfSelectedProgramTerms = selectedProgramTerms?.length ?? 0;

  return {
    selectedPublishers,
    numberOfSelectedPublishers,
    offerToPublishers,
    selectedProgramTerms,
    numberOfSelectedProgramTerms,
    replaceProgramTerms,
  };
};
