import { FormattedMessage } from "../../../components/FormattedMessage";
import { useAlert } from "../hooks";
import "../stylesheets/Alerts.css";
import { AlertProps } from "@cjdev-internal/visual-stack-x/Alert";
import { useEffect } from "react";

type TimeZoneErrorsAlertProps = {
  showAlert: (props: AlertProps | null) => void;
};

export const TimeZoneErrorsAlert = ({ showAlert }: TimeZoneErrorsAlertProps) => {
  const { showTimeZoneAlert, setShowTimeZoneAlert } = useAlert();

  useEffect(() => {
    if (showTimeZoneAlert) {
      showAlert({
        type: "info",
        closeType: "icon",
        onClose: () => {
          setShowTimeZoneAlert(false);
          showAlert(null);
        },
        children: (
          <FormattedMessage
            id="relationships.timeZoneErrorsAlert"
            defaultMessage="You've changed the timezone, please check your start dates"
          />
        ),
      });
    }
  }, [showTimeZoneAlert, showAlert, setShowTimeZoneAlert]);
  return null;
};
