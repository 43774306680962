import { ModalProps, useModal } from "@cjdev-internal/visual-stack-x/components/Modal";
import { ProviderProps } from "localTypes/ProviderTypes";
import { createContext } from "react";

export type ModalsContextType = {
  mount: JSX.Element;
  openModal: (content: ModalProps | JSX.Element | null) => void;
  closeModal: () => void;
  providerInitialized: boolean;
};

export const ModalContext = createContext<ModalsContextType>({
  mount: <></>,
  openModal: (content: null) => {},
  closeModal: () => {},
  providerInitialized: false,
} as ModalsContextType);

export const ModalProvider = ({ children }: ProviderProps) => {
  const [mount, openModal, closeModal] = useModal();
  return (
    <ModalContext.Provider
      value={{
        mount,
        openModal,
        closeModal,
        providerInitialized: true,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
