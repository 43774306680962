import { ProviderProps } from "localTypes/ProviderTypes";
import { createContext } from "react";
import { User } from "@platform/cj-platform-navigation/types/lib";

export type UserContextType = {
  user: User;
  providerInitialized: boolean;
};

export const UserContext = createContext<UserContextType>({
  user: {} as User,
  providerInitialized: false,
});

export type UserProviderProps = ProviderProps & {
  user: User;
};

export const UserProvider = ({ children, user }: UserProviderProps) => {
  return <UserContext.Provider value={{ user: user, providerInitialized: true }}>{children}</UserContext.Provider>;
};
