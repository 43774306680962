import { defineMessages } from "react-intl";

export default defineMessages({
  programTerms: {
    id: "relationships.programTerms",
    defaultMessage: "Program Terms",
  },
  creatorIds: {
    id: "relationships.creatorIds",
    defaultMessage: "Creator IDs",
  },
  creatorIdsHelpText: {
    id: "relationships.creatorIdsInputUsage",
    defaultMessage: "Enter 1 or more Creator IDs comma separated",
  },
  createSignUpLinksHeader: {
    id: "relationships.createSignUpLinkTitle",
    defaultMessage: "Create One-time Influencer Signup Link",
  },
  invalidCreatorIds: {
    id: "relationships.creatorIdsNumericOnlyError",
    defaultMessage: "Creator IDs must be numeric.",
  },
  creatorIdsRequired: {
    id: "relationships.creatorIdsIsRequiredErrorMessage",
    defaultMessage: "Creator IDs are required.",
  },
  programTermsRequired: {
    id: "relationships.programTermsIsRequiredErrorMessage",
    defaultMessage: "Program terms is required.",
  },
});
