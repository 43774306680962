import { User } from "@platform/cj-platform-navigation/types/lib";
import { currentCompanyId, isUserForAdvertiser } from "utils/authorizationUtils";

export type Authorizer = { (user: User): boolean };
export type AuthorizerFactory = { (authorizers: Authorizer[]): Authorizer };

export const currentCompanyMatchesQueryParam: Authorizer = (user) => {
  const advertiserId = currentCompanyId(user);
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.has("advertiserId")) {
    const advertiserIdParam = queryParams.get("advertiserId");
    return advertiserIdParam === advertiserId.toString();
  }
  return true;
};

export const isAdvertiserUser: Authorizer = (user) => {
  const advertiserId = currentCompanyId(user);
  return user && isUserForAdvertiser(advertiserId, user);
};

export const isSuperUserOrOperator: Authorizer = (user: User) => {
  return ["SUPERUSER", "OPERATOR"].includes(user.currentCompany.companyRole);
};

export const isInternalSupportEmployee: Authorizer = (user) => {
  const isSupportRole = (role: string) => role === "internal_support" || role === "eu_internal_support";
  const isUserInternalSupport = user?.employeeRoles.some(isSupportRole);
  const isImpersonatorInternalSupport = user?.impersonator?.employeeRoles?.some(isSupportRole);
  return isCjEmployee(user) && (isImpersonatorInternalSupport || isUserInternalSupport);
};

export const isCjEmployee: Authorizer = (user) => user?.impersonator?.isEmployee || user?.isEmployee;

export const allOf: AuthorizerFactory = (authorizers) => {
  return (user) => authorizers.every((f) => f(user));
};

export const anyOf: AuthorizerFactory = (authorizers) => {
  return (user) => authorizers.some((f) => f(user));
};

export const brandedSignupAuthorizer = allOf([
  currentCompanyMatchesQueryParam,
  isCjEmployee,
  anyOf([isAdvertiserUser, isInternalSupportEmployee]),
]);
