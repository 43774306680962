import { Company, User } from "@platform/cj-platform-navigation/types/lib";
import { LegacyUser } from "types";
import { getAdvertiserIdFromUrl } from "utils/urlUtils";
import * as R from "ramda";

export const selectActingCompany = (user: LegacyUser): Company => {
  const companyId = getAdvertiserIdFromUrl();
  if (user && !R.isEmpty(user.companies)) {
    const selectedCompany: Company = R.pipe(
      R.propOr([], "companies"),
      R.find(R.propEq("id", companyId))
    )(user) as Company;

    if (selectedCompany) {
      return {
        ...selectedCompany,
        functionalCurrency: "",
      };
    }
  }

  return { id: companyId } as Company;
};

export const standardizeUser = (legacyUser: LegacyUser): User => {
  return {
    ...legacyUser,
    currentCompany: selectActingCompany(legacyUser),
  } as User;
};
